import {IDBPDatabase, IDBPObjectStore, openDB} from 'idb';
import Folder from '../domain/Folder';
import {convertEmailHeader, EmailHeader} from '../services/messages/EmailHeaderDto';
import RemoteOperationDto from '../services/messages/RemoteOperationDto';
import DbDraftEmail from '../domain/DbDraftEmail';
import {DbAttachment, DbCalendar, DbSyncStates, EmailDB} from './DbSchema';
import {ItemChangeListDto} from '../services/messages/ItemChangeListDto';
import {ItemChangesDto} from '../services/messages/ItemChangesDto';
import {EntityDto} from '../services/messages/EntityDto';
import {PgpKeyDto} from "../services/messages/PgpKeyDto";
import {InMemoryDb} from "./InMemoryDb";
import {NoteSectionDto} from "../domain/NoteSectionDto";
import {NotePageDto} from "../domain/NotePageDto";
import {GateKeeperRecordDto} from "../services/messages/GateKeeperRecordDto";
import {EmailContentDto} from "../domain/EmailContentDto";

const CURRENT_DB_VERSION: number = 23;

const SYNC_STATE_KEY = "SYNC";

const ROOT_FOLDER_KEY = "ROOT_FOLDERS";

async function initEmailDB(userName: string) {
    const dbName = `sync-db-${userName}`;
    return await openDB<EmailDB>(dbName, CURRENT_DB_VERSION,
        {
            upgrade(db, oldVersion, newVersion) {
                console.log("Upgrading to DB version ", newVersion);

                if (oldVersion < 1) {
                    db.createObjectStore('folders');

                    const emailStore = db.createObjectStore('emailHeaders', {keyPath: "Id"});
                    emailStore.createIndex("byFolderId", "FolderId");
                    emailStore.createIndex("byRead", "Read");
                    emailStore.createIndex("byFlagged", "Flagged");
                }
                if (oldVersion < 2) {
                    db.createObjectStore('operations', {keyPath: "Id"});
                }
                if (oldVersion < 3) {
                    db.createObjectStore('drafts', {keyPath: "Uid"});
                }
                if (oldVersion < 4) {
                    db.createObjectStore('emailAttachments', {keyPath: "emailUid"});
                }
                if (oldVersion < 5) {
                    db.createObjectStore('contacts', {keyPath: "Id"});
                }
                if (oldVersion < 6) {
                    const accountsStore = db.createObjectStore('gateKeeperAccounts', {keyPath: "Id"});
                    accountsStore.createIndex("byArchived", "IsArchived");
                    accountsStore.createIndex("byEntityId", "EntityId");
                }
                if (oldVersion < 7) {
                    const calendarStore = db.createObjectStore('calendars', {keyPath: "Id"});
                    calendarStore.createIndex("byUid", "Uid");
                }
                if (oldVersion < 8) {
                    db.deleteObjectStore("emailAttachments");

                    const attachmentsStore = db.createObjectStore('emailAttachments', {keyPath: "attachmentUid"});
                    attachmentsStore.createIndex("byEmailUid", "emailUid");
                }
                if (oldVersion < 9) {
                    db.createObjectStore('pgpKeys', {keyPath: "Id"});
                }
                if (oldVersion < 20) {
                    db.createObjectStore('syncState');
                }
                if (oldVersion < 21) {
                    const sectionStore = db.createObjectStore("noteSections", {keyPath: "ClientId"});
                    sectionStore.createIndex("byServerId", "Id");

                    const pagesStore = db.createObjectStore('notePages', {keyPath: "ClientId"});
                    pagesStore.createIndex("bySectionClientId", "ClientSectionId");
                    pagesStore.createIndex("byServerId", "Id");
                }
                if (oldVersion < 22) {
                    db.createObjectStore("secretKeys", {keyPath: "Id"});
                }
                if (oldVersion < 23) {
                    db.createObjectStore("emailContents", {keyPath: "Id"});
                }
            },

            blocked() {
                // TODO: another tab has the DB open with an older version
                console.info("DB blocked");
            },

            blocking() {
                // TODO: we have an old version and need to upgrade!
                console.info("DB blocking");
            },

            terminated() {
                // TODO: handle this
                console.info("DB terminated");
            },
        });
}

export default class DBManager {
    private idb: IDBPDatabase<EmailDB> | undefined;
    private inMemoryDb: InMemoryDb = new InMemoryDb();
    private initialised: boolean = false;
    private privateBrowserMode: boolean = false;

    dbInitialisedCallback: () => void = () => {
    };

    async init(userName: string) {
        try {
            console.log("Initialising DB...");
            const result = await initEmailDB(userName);
            console.log("DB initialized");

            return await this.handleDbOpened(result);
        } catch (e) {
            console.info("Trying in memory DB due to error: ", e);
            await this.startPrivateBrowserMode();
        }
    }

    private async startPrivateBrowserMode() {
        this.privateBrowserMode = true;
    }

    isPrivateBrowserMode() {
        return this.privateBrowserMode;
    }

    async handleDbOpened(newDb: IDBPDatabase<EmailDB>) {
        this.idb = newDb;

        this.dbInitialisedCallback();

        this.initialised = true;
    }

    async saveFolders(folders: Folder[]) {
        if (!this.idb) return;

        const tx = this.idb.transaction(["folders"], "readwrite");

        // tslint:disable-next-line: no-floating-promises
        tx.objectStore("folders").put(folders, ROOT_FOLDER_KEY);

        await tx.done;
    }

    async getSyncStates(): Promise<DbSyncStates> {
        return await this.idb?.get("syncState", SYNC_STATE_KEY) ?? {key: SYNC_STATE_KEY, startSyncId: -1, endSyncId: 0};
    }

    async getFolders() {
        return await this.idb?.get("folders", ROOT_FOLDER_KEY) || [];
    }

    async saveSyncStateChange(changes: ItemChangeListDto) {
        if (!this.idb) {
            this.inMemoryDb.saveSyncStateChange(changes);
            return;
        }

        const tx = this.idb.transaction(["syncState", "emailHeaders", "contacts", "calendars", "gateKeeperAccounts", "pgpKeys", "notePages", "noteSections", "secretKeys"], "readwrite");

        const emailHeaderStore = tx.objectStore("emailHeaders");
        const contactStore = tx.objectStore("contacts");
        const calendarStore = tx.objectStore("calendars");
        const accountsStore = tx.objectStore("gateKeeperAccounts");
        const publicKeysStore = tx.objectStore("pgpKeys");
        const secretKeysStore = tx.objectStore("secretKeys");
        const noteSectionStore = tx.objectStore("noteSections");
        const notePageStore = tx.objectStore("notePages");

        // tslint:disable-next-line: no-floating-promises
        tx.objectStore("syncState").put({key: SYNC_STATE_KEY, startSyncId: changes.StartSyncId, endSyncId: changes.SyncId}, SYNC_STATE_KEY);

        this.saveSyncChanges(emailHeaderStore, changes.Emails);
        this.saveSyncChanges(contactStore, changes.Contacts);

        this.saveSyncChanges(calendarStore, changes.Calendars);

        this.saveSyncChanges(accountsStore, changes.Accounts);

        this.saveSyncChanges(publicKeysStore, changes.PublicKeys);
        this.saveSyncChanges(secretKeysStore, changes.SecretKeys);

        this.saveSyncChanges(noteSectionStore, changes.NoteSections, true);
        this.saveSyncChanges(notePageStore, changes.NotePages, true);

        await this.saveSyncChangesWithClientIds(noteSectionStore, changes.NoteSections);
        await this.saveSyncChangesWithClientIds(notePageStore, changes.NotePages);

        await tx.done;
    }

    private saveSyncChanges<T extends EntityDto>(store: IDBPObjectStore<EmailDB, any[], any, "readwrite">, items: ItemChangesDto<T> | null, clientIdIndex: boolean = false) {
        if (!items) return;

        const changedOrAddedIdSet = new Set<number>();
        if (items.Changed) {
            for (const item of items.Changed) {
                changedOrAddedIdSet.add(item.Id);
                // tslint:disable-next-line: no-floating-promises
                store.put(item);
            }
        }

        if (items.DeletedIds && !clientIdIndex) {
            for (const deletedId of items.DeletedIds) {
                if (!changedOrAddedIdSet.has(deletedId)) {
                    // tslint:disable-next-line: no-floating-promises
                    store.delete(deletedId);
                }
            }
        }
    }

    private async saveSyncChangesWithClientIds<T extends EntityDto>(store: IDBPObjectStore<EmailDB, any[], any, "readwrite">, items: ItemChangesDto<T> | null) {
        if (!items) return;

        if (items.DeletedIds) {
            // @ts-ignore
            const index = store.index("byServerId");
            for (const deletedId of items.DeletedIds) {
                const item = await index.get(IDBKeyRange.only(deletedId));
                store.delete(item?.ClientId);
            }
        }
    }

    async saveDraftEmail(draftEmail: DbDraftEmail) {
        if (!this.idb) return;

        console.debug("Saving email: ", draftEmail.Uid);

        await this.idb.put("drafts", draftEmail);
    }

    async getDraftEmail(uid: string) {
        return this.idb?.get("drafts", uid);
    }

    async getAllDraftEmails() {
        return this.idb?.getAll("drafts");
    }

    async getAllContacts() {
        return this.idb?.getAll("contacts");
    }

    async getAllCalendars(): Promise<DbCalendar[] | undefined> {
        return this.idb?.getAll("calendars");
    }

    async deleteDraftEmails(uids: string[]) {
        for (const uid of uids) {
            await this.idb?.delete("drafts", uid);
        }
    }

    async getEmailHeadersInFolder(folderId: number): Promise<EmailHeader[]> {
        if (!this.idb) return [];

        console.debug("Loading emails in folder: " + folderId);

        const tx = this.idb.transaction("emailHeaders", "readonly");

        const emailHeaders = await tx.store.index("byFolderId").getAll(folderId);

        return emailHeaders.map(convertEmailHeader);
    }

    async getEmailHeaders(emailIds: number[]): Promise<Map<number, EmailHeader>> {

        const results = new Map<number, EmailHeader>();
        if (!this.idb) return results;

        for (const id of emailIds) {
            const emailHeader = await this.idb.get("emailHeaders", id);
            if (emailHeader) {
                // TODO: apply local changes here!

                results.set(emailHeader.Id, convertEmailHeader(emailHeader));
            }
        }

        return results;
    }

    async getEmailContents(emailIds: number[]): Promise<Map<number, EmailContentDto>> {
        const results = new Map<number, EmailContentDto>();
        if (!this.idb) return results;

        for (const id of emailIds) {
            const emailContent = await this.idb.get("emailContents", id);
            if (emailContent) {
                results.set(emailContent.Id, emailContent);
            }
        }

        return results;
    }

    async saveEmailContent(emailContent: EmailContentDto) {
        if (!this.idb) return;

        await this.idb.put("emailContents", emailContent);
    }

    async addOperation(operation: RemoteOperationDto): Promise<void> {
        await this.idb?.put("operations", operation);
    }

    async removeOperations(opIds: number[]): Promise<void> {
        if (!this.idb || opIds.length === 0) return;

        const tx = this.idb.transaction("operations", "readwrite");

        for (const id of opIds) {
            // tslint:disable-next-line: no-floating-promises
            tx.store.delete(id);
        }

        await tx.done;
    }

    async getAllOperations(): Promise<RemoteOperationDto[]> {
        return this.idb?.getAll("operations") || [];
    }

    async deleteRemoteOperation(operation: RemoteOperationDto): Promise<void> {
        return this.idb?.delete("operations", operation.Id);
    }

    async getEmailAttachment(attachmentUid: string): Promise<DbAttachment | undefined> {
        return this.idb?.get("emailAttachments", attachmentUid);
    }

    async saveEmailAttachment(attachment: DbAttachment): Promise<void> {
        if (!this.idb) return;

        console.debug("Saving attachment: ", attachment.attachmentUid);

        await this.idb.put("emailAttachments", attachment);
    }

    async deleteEmailAttachment(attachmentUid: string): Promise<void> {
        if (!this.idb) return;

        console.debug("Deleting attachment: ", attachmentUid);

        await this.idb.delete("emailAttachments", attachmentUid);
    }

    async getEmailAttachments(emailUid: string): Promise<DbAttachment[] | undefined> {
        if (!this.idb) return;

        const tx = this.idb.transaction("emailAttachments", "readonly");

        return tx.store.index("byEmailUid").getAll(emailUid);
    }

    async getAllActiveGateKeeperAccounts(): Promise<GateKeeperRecordDto[]> {
        if (!this.idb) return [];

        const tx = this.idb.transaction("gateKeeperAccounts", "readonly");

        return tx.store.index("byArchived").getAll(0);
    }

    async getGateKeeperAccountHistory(entityId: number): Promise<GateKeeperRecordDto[]> {
        if (!this.idb) return [];

        const tx = this.idb.transaction("gateKeeperAccounts", "readonly");

        return tx.store.index("byEntityId").getAll(entityId);
    }

    async getAllSecretKeys(): Promise<PgpKeyDto[]> {
        return this.idb?.getAll("secretKeys") || [];
    }

    async saveSecretKeys(secretKeys: PgpKeyDto[]): Promise<void> {
        if (!this.idb) return;

        for (const secretKey of secretKeys) {
            await this.idb.put("secretKeys", secretKey);
        }
    }

    async getAllPgpKeys(): Promise<PgpKeyDto[]> {
        return this.idb?.getAll("pgpKeys") || [];
    }

    async getPgpKey(id: number): Promise<PgpKeyDto | undefined> {
        return this.idb?.get("pgpKeys", id);
    }

    async getSecretPgpKey(id: number): Promise<PgpKeyDto | undefined> {
        return this.idb?.get("secretKeys", id);
    }

    async getAllNoteSections(): Promise<NoteSectionDto[]> {
        if (!this.idb) {
            return this.inMemoryDb.getAllNoteSections();
        }

        return this.idb.getAll("noteSections");
    }

    isInitialised() {
        return this.initialised || this.isPrivateBrowserMode;
    }

    async getNoteSectionPages(noteSectionClientId: string | undefined): Promise<NotePageDto[]> {
        if (noteSectionClientId === undefined) return [];
        if (!this.idb) {
            return this.inMemoryDb.getNoteSectionPages(noteSectionClientId);
        }

        const tx = this.idb.transaction("notePages", "readonly");

        return tx.store.index("bySectionClientId").getAll(noteSectionClientId);
    }
}

// TODO: remove this export!!
export const DB = new DBManager();
