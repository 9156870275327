import {locationManager} from '../BootStrap';
import {AppState} from '../AppState';
import {LocationProperties} from '../util/LocationParser';
import {EmailHeader} from "../services/messages/EmailHeaderDto";
import {GreylistFolderId} from "../reducers/FolderReducer";
import {
    EmailLocation,
    handleAnalyzeEmail,
    handleGetEmailSource,
    handleViewEmail,
    markSelectedEmailAsRead
} from "./EmailLocation";
import {handleViewContact} from "./ContactLocation";
import {CLEAR_ITEM_CONTENT, LocationItemType} from "../actions/EmailActionTypes";
import {uuid} from "../util/Uuid";
import {createAppProperties} from "./LocationUtil";
import {handleViewCalendar} from "./CalendarLocation";
import {AppDispatch} from "../AppStore";
import {handleViewAccount} from "./AccountLocation";
import {ItemActions} from "../actions/ItemActions";
import {RulesLocation} from "./RulesLocation";
import {PhotoDto} from "../services/photos/dto/PhotoDto";
import {PhotosActions} from "../redux/PhotosSlice";
import {PhotoFolderDto} from "../services/photos/dto/PhotoFolderDto";
import {isDummyContent} from "../domain/ItemContent";
import {createDraftEmailFromMailToUrl} from "../util/DraftEmailUtil";
import {DB} from "../db/DbManager";
import {ServiceWorkerMessage} from "../domain/ServiceWorkerMessaging";
import {parseIntSafe} from "../util/Formatters";

export interface ContentLocationProperties {
    itemType?: LocationItemType;
    itemId?: number;
    itemIdStr?: string;
}

export const ContentLocationPropertyNames = createAppProperties<ContentLocationProperties>("itemType", "itemId", "itemIdStr");

export class ContentLocation {
    static clearItemContent() {
        locationManager.modifyWindowLocation(undefined, location => {
            delete location.itemId;
            delete location.itemIdStr;
            delete location.itemType;
            delete location.kH;     // PGP key
            delete location.uId;    // User
        });
    }

    static showContact(contactId: number, contactClientId: string | undefined): void {
        if (contactId > -1) {
            locationManager.modifyWindowLocation("email", location => {
                location.itemId = contactId;
                location.itemType = "contact";
                delete location.itemIdStr;
            });
        } else {
            locationManager.updateWindowLocation("email", {itemId: contactId, itemIdStr: contactClientId, itemType: "contact"});
        }
    }

    static showPhoto(breadcrumbs: string[] | undefined, photo: PhotoDto | undefined) {
        if (!photo || !breadcrumbs) {
            locationManager.modifyWindowLocation("email", location => {
                location.itemType = "photo";
                delete location.itemIdStr;
            });
        } else {
            const folder = breadcrumbs.join("/");

            locationManager.updateWindowLocation("email", {itemIdStr: folder + "/" + photo.Name, itemType: "photo"});
        }
    }

    static showPhotoFolder(breadcrumbs: string[], folder: PhotoFolderDto | undefined) {
        const folderName = breadcrumbs.join("/") + (breadcrumbs.length > 0 ? "/" : "") + (folder ? folder.Name : "");
        locationManager.updateWindowLocation("email", {itemIdStr: folderName, itemType: "photofolder"});
    }

    static selectEmailHeader(emailHeader: EmailHeader) {
        if (emailHeader.FolderId === GreylistFolderId) {
            locationManager.updateWindowLocation("email", {itemIdStr: emailHeader.ServerId, itemType: "greylisted"});
        } else if (!emailHeader.IsDraft) {
            locationManager.updateWindowLocation("email", {itemId: emailHeader.Id, itemType: "email"});
        }
    }

    static showOutboxEmail(emailUid: string) {
        locationManager.updateWindowLocation("email", {itemIdStr: emailUid, itemType: "outbox"});
    }

    static showEmailAnalysis(emailId: number) {
        locationManager.updateWindowLocation("email", {itemId: emailId, itemType: "analyze"});
    }

    static showEmailSource(emailId: number) {
        locationManager.updateWindowLocation("email", {itemId: emailId, itemType: "source"});
    }

    static showEmailRawSource(emailId: number) {
        locationManager.updateWindowLocation("email", {itemId: emailId, itemType: "rawsource"});
    }

    static editContact(contactId: number, contactClientId: string | undefined) {
        locationManager.updateWindowLocation("email", {itemId: contactId, itemIdStr: contactClientId, itemType: "editcontact"});
    }

    static showCalendar(calendarUid: string, instanceId: number) {
        locationManager.updateWindowLocation("email", {itemId: instanceId, itemIdStr: calendarUid, itemType: "calendar"});
    }

    static editCalendar(calendarUid: string, instanceId: number) {
        locationManager.updateWindowLocation("email", {itemId: instanceId, itemIdStr: calendarUid, itemType: "editcalendar"});
    }

    static showAccount(accountId: number, accountUid: string) {
        locationManager.updateWindowLocation("email", {itemId: accountId, itemIdStr: accountUid, itemType: "account"});
    }

    static showEmailRule(ruleId: number) {
        locationManager.updateWindowLocation(undefined, {itemId: ruleId, itemType: "rule"});
    }

    static showCreateEmailRuleFromEmail() {
        locationManager.updateWindowLocation(undefined, {itemId: -2, itemType: "rule"});
    }

    static createContact() {
        ContentLocation.editContact(-1, uuid());
    }

    static handleLocationChange(dispatch: AppDispatch, getState: () => AppState, newLocation: LocationProperties, oldLocation: LocationProperties): void {
        const state = getState();
        const {itemState} = state;
        const {itemId, itemType} = itemState;

        const newItemId = newLocation.itemId;
        const newItemIdStr = newLocation.itemIdStr;
        const newItemType = newLocation.itemType;

        if (newLocation.mailto) {
            const draftEmail = createDraftEmailFromMailToUrl(newLocation.mailto, uuid());
            DB.saveDraftEmail(draftEmail).then(() => {
                locationManager.modifyWindowLocation(newLocation.app, l => delete l.mailto);
                EmailLocation.openDraftEmailByUid(draftEmail.Uid);
            }, console.error);
        }

        if ((newItemType !== itemType || newItemId !== itemId) && oldLocation.itemType === "email") {
            markSelectedEmailAsRead(dispatch, itemState.selectedEmailHeaders);
        }

        if (!newItemType) {
            dispatch({type: CLEAR_ITEM_CONTENT});
            return;
        }

        if (newItemType !== oldLocation.itemType || newItemId !== oldLocation.itemId || newItemIdStr != oldLocation.itemIdStr || (newItemId && isDummyContent(itemState.itemContent))) { // eslint-disable-line eqeqeq
            dispatch(ItemActions.setItemContent(newItemId, newItemIdStr, newItemType));

            switch (newItemType) {
                case "emailthread":
                case "email":
                    newItemId && handleViewEmail(newItemId, dispatch);
                    break;
                case "greylisted":
                    handleGetEmailSource(0, newItemIdStr, false, true, dispatch);
                    break;
                case "outbox":
                    handleGetEmailSource(0, newItemIdStr, true, true, dispatch);
                    break;
                case "analyze":
                    newItemId && handleAnalyzeEmail(newItemId, dispatch);
                    break;
                case "source":
                    newItemId && handleGetEmailSource(newItemId, undefined, false, true, dispatch);
                    break;
                case "rawsource":
                    newItemId && handleGetEmailSource(newItemId, undefined, false, false, dispatch);
                    break;

                case "editcontact":
                case "contact":
                    newItemId && handleViewContact(state, newItemId, newItemIdStr, dispatch);
                    break;

                case "editaccount":
                case "account":
                    newItemId && handleViewAccount(state, newItemId, newItemIdStr, dispatch)
                        .catch(console.error);
                    break;

                case "calendar":
                    handleViewCalendar(state, newItemId, newItemIdStr, dispatch)
                        .catch(console.error);
                    break;

                case "rule":
                    newItemId && RulesLocation.handleViewEmailRule(state, newItemId, dispatch)
                        .catch(console.error);
                    break;

                case "photo":
                    dispatch(PhotosActions.setSelectedPhoto(newItemIdStr === "" ? undefined : newItemIdStr));
                    break;

                case "photofolder":
                    dispatch(PhotosActions.selectFolder("" + newItemIdStr));
                    break;
            }
        }
    }

    static showIncrementalPhoto({photosState: state}: AppState, direction: number) {
        const index = state.photos.findIndex(p => p.Name === state.selectedPhotoName);
        if (index === -1) {
            return;
        }

        const newIndex = index + direction;
        if (newIndex >= 0 && newIndex < state.photos.length) {
            ContentLocation.showPhoto(state.breadcrumbs, state.photos[newIndex]);
        }
    }

    static handleOpenWindowMessage(data: ServiceWorkerMessage) {
        const contactId = parseIntSafe(data.contactId);

        if (contactId) {
            ContentLocation.showContact(contactId, undefined);
        }
    }
}
