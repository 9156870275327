import React, {ReactNode, useState} from "react";
import {Button, ButtonGroup} from "@mui/material";
import "./ToolbarView.scss";
import MarkunreadIcon from '@mui/icons-material/Markunread';
import DraftsIcon from '@mui/icons-material/Drafts';
import FlagIcon from '@mui/icons-material/Flag';
import DeleteIcon from '@mui/icons-material/Delete';
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CategoryIcon from '@mui/icons-material/Category';
import ReplyIcon from '@mui/icons-material/Reply';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import ForwardIcon from '@mui/icons-material/Forward';
import BuildIcon from '@mui/icons-material/Build';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import HistoryIcon from '@mui/icons-material/History';
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe';
import {DropDownMenu, DropDownMenuItem} from "./DropDownMenu";
import {EmailActions} from "../../actions/EmailActions";
import {AccountActionHelper} from "../../actions/AccountActionHelper";
import {EmailHeader} from "../../services/messages/EmailHeaderDto";
import CancelIcon from '@mui/icons-material/Cancel';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import HttpsIcon from '@mui/icons-material/Https';
import AddIcon from '@mui/icons-material/Add';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import {EmailLocation} from "../../locations/EmailLocation";
import {isJunkFolder, isOutgoingFolder} from "../../reducers/FolderReducer";
import {FolderTypeEnum} from "../../domain/Folder";
import {isEmailContent} from '../../domain/ItemContent';
import ConfirmDialog, {ConfirmDialogProps} from '../common/ConfirmDialog';
import {performRestCallDirectly} from "../../util/HttpHelper";
import {ToolbarButton} from "./ToolbarTypes";
import {ContentLocation} from "../../locations/ContentLocation";
import {useAppDispatch, useAppSelector, useAppStore} from "../../hooks/ReduxHooks";
import {TextFieldDialog} from "../common/TextFieldDialog";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import {createDefaultCalendarEntry} from "../../services/calendar/CalendarFactory";
import {ItemActions} from "../../actions/ItemActions";
import {DateTime} from "luxon";
import {downloadFile} from "../../util/FileUtil";
import NotificationsPausedIcon from "@mui/icons-material/NotificationsPaused";
import {OutgoingEmailDto} from "../../services/messages/OutgoingEmailDto";
import {MailHelperService} from "../../services/common/MailHelperService";
import PushPinIcon from "@mui/icons-material/PushPin";

const TOOLBAR_OFFSET = 265;

interface EmailToolbarProps {
    reducedMenu?: boolean;
}

export const EmailToolbar = ({reducedMenu}: EmailToolbarProps) => {

    const dispatch = useAppDispatch();
    const store = useAppStore();

    const folderTreeWidth = useAppSelector(s => s.system.folderTreeWidth);
    const selectedEmailHeaders = useAppSelector(s => s.itemState.selectedEmailHeaders);
    const selectedOutgoingEmails = useAppSelector(s => s.itemState.selectedOutgoingEmails);
    const itemContent = useAppSelector(s => s.itemState.itemContent);
    const selectedFolder = useAppSelector(s => s.folderState.selectedFolder);

    const haveEmailContent = useAppSelector(s => isEmailContent(s.itemState.itemContent) && selectedEmailHeaders[0]?.Id === s.itemState.itemContent.Id);

    const canUnsubscribe = useAppSelector(s => {
        const content = s.itemState.itemContent;
        if (!isEmailContent(content)) return false;
        const email = content.Emails.find(e => content.Id === e.Id);
        return email && (email.CanUnsubscribe || email.UnsubscribeUrl);
    });

    const [showChangeSubjectDialog, setShowChangeSubjectDialog] = useState<boolean>(false);

    const [confirmDialogProps, setConfirmDialogProps] = useState<ConfirmDialogProps>({
        title: "", message: "", open: false, onConfirm: () => {
        }
    });

    const isMobile = useAppSelector(({system}) => system.isMobile);

    const ConfirmDeleteProps: ConfirmDialogProps = {
        title: "Delete Emails",
        message: `Are you sure you want to delete ${selectedEmailHeaders.length} emails?`,
        open: false,
        onConfirm: onDelete,
    }

    const ConfirmUnsubscribeProps: ConfirmDialogProps = {
        title: "Unsubscribe",
        message: `Are you sure you want to unsubscribe from this mailing list from ${selectedEmailHeaders[0]?.DisplayFrom}?`,
        open: false,
        onConfirm: async () => {
            await MailHelperService.unsubscribeFromMailingList(dispatch, selectedEmailHeaders[0].Id, typeof canUnsubscribe === "string" ? canUnsubscribe : undefined);
        },
    }

    function startUnsubscribing() {
        if (("" + canUnsubscribe).startsWith("mailto")) {
            ConfirmUnsubscribeProps.onConfirm(true);
        } else {
            confirmAction(ConfirmUnsubscribeProps, true);
        }
    }

    const notJunk = isJunkFolder(selectedFolder?.icon) ? "Not Junk" : "Junk";
    const ConfirmMarkAsJunkProps: ConfirmDialogProps = {
        title: `Mark as ${notJunk}`,
        message: `Are you sure you want to mark ${selectedEmailHeaders.length} emails as ${notJunk}?`,
        open: false,
        onConfirm: onMarkAsJunk,
    }

    const ConfirmStopSendingProps: ConfirmDialogProps = {
        title: "Stop Sending",
        message: "Are you sure you want to stop trying to send these emails? Note that some of the recipients might have already received the email",
        open: false,
        onConfirm: onStopSending,
    }

    function confirmAction(props: ConfirmDialogProps, alwaysConfirm = false) {
        if (selectedEmailHeaders.length > 1 || alwaysConfirm) {
            setConfirmDialogProps({
                ...props,
                open: true,
                onConfirm: confirmed => {
                    setConfirmDialogProps({...props, open: false});
                    if (confirmed) {
                        props.onConfirm(true);
                    }
                }
            });
        } else {
            props.onConfirm(true);
        }
    }

    async function onMarkAsJunk() {
        dispatch(await EmailActions.markEmailsAsJunk(selectedEmailHeaders, selectedFolder));
    }

    async function onDelete() {
        await EmailActions.deleteEmails(dispatch, selectedEmailHeaders);
    }

    function onCreateNewAccount() {
        AccountActionHelper.createNewAccount()(dispatch, store.getState);
    }

    function onMoveEmails(emails: EmailHeader[]) {
        dispatch(EmailActions.beginMoveEmails(emails));
    }

    async function onReleaseGreylistedEmails(emails: EmailHeader[]) {
        dispatch(await EmailActions.releaseGreylistedEmails(emails));
    }

    async function onStopSendingEmails(outgoingEmails: OutgoingEmailDto[]) {
        await EmailActions.stopSendingOutgoingEmails(outgoingEmails)(dispatch);
    }

    function onBeginChangeEmailSubject() {
        setShowChangeSubjectDialog(true);
    }

    function onCreateNewCalendarEntry() {
        const entry = createDefaultCalendarEntry(DateTime.local());
        console.log("Creating entry", entry);
        dispatch(ItemActions.createCalendarEntry(entry));
        ContentLocation.editCalendar(entry.calendar.Uid, entry.instanceId);
    }

    async function changeEmailSubject(newSubject: string | null) {
        setShowChangeSubjectDialog(false);
        if (newSubject) {
            dispatch(await EmailActions.addRemoteOperation({
                ManageEmails: {
                    EmailIds: [selectedEmailHeaders[0].Id],
                    NewSubject: newSubject
                }
            }));
        }
    }

    const responseMenuItems: DropDownMenuItem[] = [
        {
            icon: <ReplyIcon/>,
            name: "Reply",
            onClick: () => EmailLocation.respondToEmail(dispatch, itemContent, "Reply")
        },
        {
            icon: <ReplyAllIcon/>,
            name: "Reply All",
            onClick: () => EmailLocation.respondToEmail(dispatch, itemContent, "ReplyAll")
        },
        {
            icon: <ForwardIcon/>,
            name: "Forward",
            onClick: () => EmailLocation.respondToEmail(dispatch, itemContent, "Forward")
        },
    ];

    const advancedMenuItems: DropDownMenuItem[] = [
        {
            icon: <BuildIcon/>,
            name: "Analyze",
            onClick: () => ContentLocation.showEmailAnalysis(selectedEmailHeaders[0].Id)
        },
        {icon: <AutorenewIcon/>, name: "Change Email Subject", onClick: onBeginChangeEmailSubject},
        {icon: <CategoryIcon/>, name: "Change Email Category", onClick: () => dispatch(EmailActions.showEmailCategoryDialog(selectedEmailHeaders[0].Category || ""))},
        {
            icon: <AccountTreeIcon/>,
            name: "Create Rule from Email",
            onClick: () => ContentLocation.showCreateEmailRuleFromEmail()
        },
        {
            icon: <DeveloperModeIcon/>,
            name: "View Source",
            onClick: () => ContentLocation.showEmailSource(selectedEmailHeaders[0].Id)
        },
        {
            icon: <DeveloperModeIcon/>,
            name: "View Raw Source",
            onClick: () => ContentLocation.showEmailRawSource(selectedEmailHeaders[0].Id)
        },
        {
            icon: <DeveloperModeIcon/>,
            name: "Download Email Source",
            onClick: () => downloadFile(`/Mail/DownloadAttachment.aspx?sourceId=${selectedEmailHeaders[0].Id}`, "email-source.txt"),
        },
        {
            icon: <DoubleArrowIcon/>,
            name: "Send Push Notification",
            onClick: () => performRestCallDirectly("PushNotification", {EmailId: selectedEmailHeaders[0].Id}).catch(error => console.error("Error sending push notifications", error)),
        },
    ];

    const hasReadEmail = selectedEmailHeaders.some(e => e.Read);
    const hasUnreadEmail = selectedEmailHeaders.some(e => !e.Read);

    const isOutgoing = isOutgoingFolder(selectedFolder?.type);
    const isJunk = isJunkFolder(selectedFolder?.icon);

    let toolbarOffset = (folderTreeWidth - TOOLBAR_OFFSET);
    if (toolbarOffset < 0 || isMobile) {
        toolbarOffset = 0;
    }

    async function onStopSending() {
        await onStopSendingEmails(selectedOutgoingEmails)
    }

    let toolbarButtons: ReactNode;

    if (selectedFolder?.type === FolderTypeEnum.Outbox) {
        toolbarButtons = <ToolbarButton title="Stop Sending Emails" label="Stop Sending"
                                        onClick={() => confirmAction(ConfirmStopSendingProps, true)} icon={<CancelIcon/>}/>;

    } else if (selectedFolder?.type === FolderTypeEnum.Greylist) {
        toolbarButtons =
            <ToolbarButton title="Release Email" onClick={() => onReleaseGreylistedEmails(selectedEmailHeaders)}
                           icon={<BeenhereIcon/>}/>;

    } else {
        toolbarButtons = (<>

            {selectedEmailHeaders.length > 0 && (<>
                <DropDownMenu className="button-group"
                              menuItems={responseMenuItems}
                              disabled={selectedEmailHeaders.length !== 1 || !haveEmailContent}/>

                <ButtonGroup variant="text" className={toolbarOffset < 10 ? "button-group-first" : "button-group"}>

                    {!reducedMenu && hasReadEmail && !isOutgoing && <Button title="Mark as Unread"
                                                                            onClick={_ => EmailActions.markEmailsAsRead(dispatch, selectedEmailHeaders, false)}>
                        <MarkunreadIcon/>
                        <span className="label">Mark Unread</span>
                    </Button>}

                    {!reducedMenu && hasUnreadEmail && !isOutgoing && <Button title="Mark as Read"
                                                                              onClick={_ => EmailActions.markEmailsAsRead(dispatch, selectedEmailHeaders, true)}>
                        <DraftsIcon/>
                        <span className="label">Mark Read</span>
                    </Button>}

                    <Button title="Toggle Flag"
                            onClick={_ => EmailActions.setEmailFlags(dispatch, selectedEmailHeaders, selectedEmailHeaders.length > 0 && !selectedEmailHeaders[0].Flagged)}
                            disabled={isOutgoing}>
                        <FlagIcon/>
                        <span className="label">Toggle Flag</span>
                    </Button>

                    <Button title="Toggle Pinned"
                            onClick={_ => EmailActions.setEmailPinned(dispatch, selectedEmailHeaders, selectedEmailHeaders.length > 0 && !selectedEmailHeaders[0].Pinned)}
                            disabled={isOutgoing}>
                        <PushPinIcon/>
                        <span className="label">Toggle Pinned</span>
                    </Button>

                    {!reducedMenu && <Button title="Move to Different Folder" onClick={_ => onMoveEmails(selectedEmailHeaders)}
                                             disabled={isOutgoing}>
                        <AssignmentReturnIcon/>
                        <span className="label">Move</span>
                    </Button>}

                    {!reducedMenu && <Button title="Remind Me Later"
                                             onClick={_ => dispatch(EmailActions.showRemindMeLaterSettingsDialog(true))}
                                             disabled={isOutgoing || !haveEmailContent}>
                        <NotificationsPausedIcon/>
                        <span className="label">Remind Me Later</span>
                    </Button>}

                    {!reducedMenu && <Button title="Change Archiving Settings"
                                             onClick={_ => dispatch(EmailActions.openArchiveSettingsDialog())}
                                             disabled={isOutgoing || !haveEmailContent}>
                        <HistoryIcon/>
                        <span className="label">Archive Settings</span>
                    </Button>}
                </ButtonGroup>

                <ButtonGroup variant="text" className={isOutgoing ? "button-group-last" : "button-group"}>
                    <Button title={isJunk ? "Mark as not Junk" : "Mark as Junk"}
                            onClick={_ => confirmAction(ConfirmMarkAsJunkProps)}
                            disabled={isOutgoing}>
                        {isJunk ? <CheckCircleIcon/> : <BlockIcon/>}
                        <span className="label">{isJunk ? "Mark as not junk" : "Mark as Junk"}</span>
                    </Button>

                    <Button title="Delete"
                            onClick={_ => confirmAction(ConfirmDeleteProps)}><DeleteIcon/><span
                        className="label">Delete</span>
                    </Button>

                    {canUnsubscribe && !reducedMenu && <Button title="Unsubscribe"
                                                               onClick={_ => startUnsubscribing()}><UnsubscribeIcon/><span
                        className="label">Unsubscribe</span>
                    </Button>}
                </ButtonGroup>
            </>)}

            {!reducedMenu && <DropDownMenu className="button-group-last"
                                           menuItems={advancedMenuItems}
                                           hidden={selectedEmailHeaders.length !== 1}/>}

        </>);
    }

    return (
        <>
            {!isMobile && <>
                <ToolbarButton title="Compose Email" onClick={EmailLocation.composeNewEmail}
                               icon={<><EmailIcon/><AddIcon className="add-icon2"/><AddIcon className="add-icon"/></>}/>

                <ToolbarButton title="Add Contact" onClick={() => ContentLocation.createContact()}
                               icon={<><PersonIcon/><AddIcon className="add-icon2"/><AddIcon
                                   className="add-icon"/></>}/>

                <ToolbarButton title="Add Secure Account" label="Add Account" onClick={onCreateNewAccount}
                               icon={<><HttpsIcon/><AddIcon className="add-icon2"/><AddIcon className="add-icon"/></>}/>

                <ToolbarButton title="Add Calendar Event" label="Add Event" onClick={onCreateNewCalendarEntry}
                               icon={<><CalendarTodayIcon/><AddIcon className="add-icon2"/><AddIcon
                                   className="add-icon"/></>}/>
            </>}

            <div style={{paddingLeft: toolbarOffset + "px"}}/>

            {isMobile && !reducedMenu ? <div className="mobile-toolbar">{toolbarButtons}</div> : toolbarButtons}

            <ConfirmDialog confirmColour="error" {...confirmDialogProps}/>

            <TextFieldDialog title="Change Email Subject"
                             message="Enter the new email subject below:"
                             initialValue={selectedEmailHeaders[0]?.Subject}
                             open={showChangeSubjectDialog}
                             onClose={changeEmailSubject}/>
        </>
    );
};
