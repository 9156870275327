import {
    ANALYZE_EMAIL_ASYNC,
    CLEAR_ITEM_CONTENT,
    DELETE_DRAFT_EMAILS,
    DRAFT_EMAIL_HEADERS_LOADED,
    GET_EMAIL_CONTENT_ASYNC,
    GET_EMAIL_SOURCE_ASYNC,
    GetEmailSourceAsyncAction,
    REMOVE_GREYLISTED_EMAILS,
    REMOVE_REMOTE_OPERATIONS,
    SELECT_ALL_EMAIL_HEADERS,
    SELECT_CATEGORY_FILTERS,
    SELECT_EMAIL_HEADERS,
    SELECT_OUTGOING_EMAILS,
    SET_AUTO_ARCHIVE_DATE,
    SET_AUTO_ARCHIVE_DATE_ON_EMAIL,
    SET_CONTEXT_ATTACHMENT,
    SET_REMIND_ME_LATER_DATE,
    SET_REMIND_ME_LATER_DATE_ON_EMAIL,
    SHOW_ARCHIVE_SETTINGS_DIALOG,
    SHOW_ATTACHMENT_CONTENT,
    SHOW_EMAIL_CATEGORY_DIALOG,
    SHOW_EMAIL_CONTENT,
    SHOW_EMAIL_HEADERS,
    SHOW_IMAGE_ATTACHMENT,
    SHOW_INLINE_ATTACHMENT,
    SHOW_REMIND_ME_LATER_SETTINGS_DIALOG,
    TOGGLE_EXPAND_CONTENT,
} from "../actions/EmailActionTypes";
import {AllActionTypes} from "../actions/AllActionTypes";
import EmailHeaderDto, {convertEmailHeader, EmailHeader} from "../services/messages/EmailHeaderDto";
import RemoteOperationDto from "../services/messages/RemoteOperationDto";
import {initialItemState, ItemState, LoadingItemContent, NoItemContent} from "../state/ItemState";
import EmailChangesDto from "../services/messages/EmailChangesDto";
import {applyChangeToEmailContentAndHeader, applySyncChanges, removeDuplicates} from './SyncHelper';
import {isAccountContent, isEmailContent, ItemContent} from '../domain/ItemContent';
import {ItemChangeListDto} from '../services/messages/ItemChangeListDto';
import {GET_GREYLISTED_EMAILS_ASYNC, PERFORM_SEARCH_ASYNC, SEARCH_RESULTS_LOADED, SELECT_FOLDER} from '../actions/FolderActionTypes';
import {FolderState} from '../state/FolderState';
import {AttachmentDto} from '../domain/AttachmentDto';
import {EmailActions} from '../actions/EmailActions';
import {AMEND_DRAFT_EMAIL, COMPOSE_EMAIL} from '../actions/ComposeEmailActionTypes';
import DbDraftEmail from '../domain/DbDraftEmail';
import {SHOW_CONTACT} from "../actions/ContactActionTypes";
import {ItemChangesDto} from "../services/messages/ItemChangesDto";
import {CalendarState} from "../state/CalendarState";
import {CREATE_CALENDAR_ENTRY, SERIES_CALENDAR_UID, SHOW_CALENDAR_ENTRY, ShowCalendarEntryAction} from "../actions/CalendarActionTypes";
import {CalendarEntryData, NonNullInterval} from "../services/calendar/CalendarEntryData";
import {Interval} from "luxon";
import {ADD_REMOTE_OPERATION, SYNC_RESPONSE_RECEIVED} from "../redux/SyncSlice";
import {BEGIN_SELECT_ACCOUNT, CANCEL_PASSWORD_ENTRY, SELECT_ACCOUNT, SET_EMAIL_VIEW_OPTIONS, SET_ITEM_CONTENT, SHOW_ITEM_CONTENT} from "../actions/ItemActions";
import {EmailDataSourceResult} from "../domain/EmailDataSource";
import {FolderTypeEnum} from "../domain/Folder";
import {calculateDateFromRelativeDateOption} from "../util/DateHelper";
import {androidApp, isAndroidApp} from "../util/DeviceUtil";
import {FullEmailContent} from "../domain/EmailContentDto";
import {EmailViewOptionsPref} from "../util/Preferences";

function getAttachmentImageUrl(attachment: AttachmentDto) {
    return `/Mail/DownloadAttachment.aspx?inline=1&id=${attachment.Id}`;
}

function sortEmailHeaders(emailHeaders: EmailHeader[]) {
    emailHeaders.sort((a, b) => {
        if (!!b.Pinned && !a.Pinned) return 1;
        if (!b.Pinned && !!a.Pinned) return -1;
        const dateDiff = (b.DisplayDate ?? b.Date) - (a.DisplayDate ?? a.Date);
        if (dateDiff !== 0) {
            return dateDiff;
        }
        return b.Id - a.Id;
    });
    return emailHeaders;
}

export default function itemContentReducer(state = initialItemState, calendarState: CalendarState, folderState: FolderState, action: AllActionTypes): ItemState {
    switch (action.type) {
        case SET_ITEM_CONTENT:
            return {
                ...state,
                itemId: action.itemId,
                itemIdStr: action.itemIdStr,
                itemType: action.itemType,
            };

        case SHOW_ITEM_CONTENT:
            return {...state, itemContent: action.itemContent};

        case SET_EMAIL_VIEW_OPTIONS:
            EmailViewOptionsPref.set(action.emailViewOptions);
            return {...state, emailViewOptions: action.emailViewOptions};

        case SELECT_FOLDER: {
            // Deselect all items when changing folder
            let selectedEmailHeaders: EmailHeader[] = state.selectedEmailHeaders;
            if (selectedEmailHeaders.length > 2 || (isEmailContent(state.itemContent) && state.itemContent.Id !== selectedEmailHeaders[0]?.Id)) {
                selectedEmailHeaders = [];
            }
            if (action.virtualDataSource) {
                return {
                    ...state,
                    emailHeaders: [],
                    selectedEmailHeaders,
                    selectedOutgoingEmails: [],
                    allEmailHeadersLoaded: true,
                    itemLoadingInProgress: true,
                };
            }
            return {
                ...state,
                selectedEmailHeaders,
                selectedOutgoingEmails: [],
                allEmailHeadersLoaded: true,
                itemLoadingInProgress: true,
                selectedCategoriesFilter: [],
            };
        }
        case DRAFT_EMAIL_HEADERS_LOADED:
            return {...state, draftEmailHeaders: action.draftEmailHeaders};

        case SELECT_CATEGORY_FILTERS:
            return {...state, selectedCategoriesFilter: action.filters};

        case SELECT_EMAIL_HEADERS:
            return {...state, selectedEmailHeaders: action.emails, selectedOutgoingEmails: []};
        case SELECT_OUTGOING_EMAILS:
            return {...state, selectedOutgoingEmails: action.outgoingEmails, selectedEmailHeaders: []};
        case SELECT_ALL_EMAIL_HEADERS:
            const headers = folderState.selectedFolder?.type === FolderTypeEnum.Drafts ? state.draftEmailHeaders : state.emailHeaders;
            return {
                ...state,
                selectedEmailHeaders: action.select ? headers : [],
                selectedOutgoingEmails: []
            };

        case GET_EMAIL_CONTENT_ASYNC:
            const selectedEmailHeaderId = action.emailId || action.content?.Id;

            const selectedEmailHeaders = state.emailHeaders.filter(h => (h.LocalId || h.Id) === selectedEmailHeaderId);

            if (action.content) {
                return {
                    ...state,
                    itemContent: action.content,
                    isLoadingContent: false,
                    selectedAttachmentId: undefined,
                    imageContent: undefined,
                    inlineContent: undefined,
                    selectedEmailHeaders,
                };
            } else if (action.error) {
                console.error("Error getting email: " + action.error.message);
                return {...state, isLoadingContent: false, itemContent: NoItemContent};
            }
            return {
                ...state,
                selectedEmailHeaders,
                isLoadingContent: !!action.emailId,
                itemContent: LoadingItemContent,
            };

        case ANALYZE_EMAIL_ASYNC:
            if (action.analysis) {
                return {
                    ...state,
                    itemContent: action.analysis,
                    isLoadingContent: false,
                    selectedAttachmentId: undefined,
                    imageContent: undefined,
                    inlineContent: undefined,
                };
            } else if (action.error) {
                console.error("Error analyzing email: " + action.error.message);
                return {...state, isLoadingContent: false};
            }
            return {
                ...state,
                isLoadingContent: !!action.emailId,
                itemContent: LoadingItemContent,
            };

        case GET_EMAIL_SOURCE_ASYNC:
            return handleGetEmailSourceAsync(state, action);

        case BEGIN_SELECT_ACCOUNT:
            return {...state, itemContent: LoadingItemContent, isLoadingContent: true};
        case SELECT_ACCOUNT:
            return {...state, itemContent: action.account, isLoadingContent: false};
        case CANCEL_PASSWORD_ENTRY:
            return {...state, isLoadingContent: false};

        case SHOW_EMAIL_HEADERS:
            // TODO: allow changing the sort field/order
            if (action.allEmailHeadersLoaded) {
                sortEmailHeaders(action.emailHeaders);
            }
            return {
                ...state,
                emailHeaders: action.emailHeaders,
                availableCategories: findAvailableCategories(action.emailHeaders),
                allEmailHeadersLoaded: action.allEmailHeadersLoaded,
                itemLoadingInProgress: false
            };
        case GET_GREYLISTED_EMAILS_ASYNC:
            if (action.response) {
                // TODO: implement paging here...
                const emailHeaders = action.response.Emails.map(convertEmailHeader);

                return {...state, emailHeaders, allEmailHeadersLoaded: true};
            }
            return state;

        case CLEAR_ITEM_CONTENT:
            return {...state, itemContent: NoItemContent};

        case TOGGLE_EXPAND_CONTENT:
            return {...state, contentExpanded: !state.contentExpanded};

        case SYNC_RESPONSE_RECEIVED:
            const syncItems = action.payload;
            if (syncItems) {
                const itemContent = deselectModifiedItems(state, syncItems);
                const emailHeaders = handleEmailSyncChanges(state, state.emailHeaders, folderState, syncItems.Emails);
                const availableCategories = findAvailableCategories(emailHeaders);
                return {
                    ...state,
                    itemContent,
                    emailHeaders,
                    availableCategories,
                };
            }
            break;
        case ADD_REMOTE_OPERATION:
            return applyRemoteOperationLocally(state, folderState, action.payload.operation);
        case REMOVE_REMOTE_OPERATIONS:
            // TODO: apply the changes locally
            return state;

        case AMEND_DRAFT_EMAIL:
        case COMPOSE_EMAIL:
            return handleComposeEmailAction(state, action.email);

        case DELETE_DRAFT_EMAILS:
            return {
                ...state,
                draftEmailHeaders: state.draftEmailHeaders.filter(e => !e.LocalId || !action.uids.includes(e.LocalId))
            };

        case SHOW_IMAGE_ATTACHMENT:
            return {
                ...state,
                selectedAttachmentId: action.attachment.Uid,
                imageContent: {url: getAttachmentImageUrl(action.attachment), emailId: action.emailId},
                inlineContent: undefined,
            };
        case SHOW_INLINE_ATTACHMENT:
            if (action.isInline) {
                return {
                    ...state,
                    selectedAttachmentId: action.attachment.Uid,
                    inlineContent: {ContentType: "loading", Content: "Loading...", IsSafeHtml: true, EmailId: action.emailId},
                };
            }
            return {...state, selectedAttachmentId: action.attachment.Uid};
        case SHOW_ATTACHMENT_CONTENT:
            return {
                ...state,
                imageContent: undefined,
                inlineContent: action.attachmentContent,
            };

        case SHOW_EMAIL_CONTENT:
            return {...state, selectedAttachmentId: undefined, imageContent: undefined, inlineContent: undefined,};

        case SHOW_CONTACT:
            if (action.contact) {
                return {...state, itemContent: action.contact};
            }
            break;

        case CREATE_CALENDAR_ENTRY:
            return {
                ...state,
                itemContent: action.calendar,
                itemId: action.calendar.instanceId,
                itemIdStr: action.calendar.calendar.Uid,
                itemType: "editcalendar"
            };

        case SHOW_CALENDAR_ENTRY:
            return showCalendarEntry(state, calendarState, action);

        case PERFORM_SEARCH_ASYNC:
            if (action.emailDataSource) {
                return handleSearchResults(state);
            }
            break;

        case SEARCH_RESULTS_LOADED:
            return handleSearchResults(state, action.results);

        case SHOW_EMAIL_CATEGORY_DIALOG:
            return {
                ...state,
                showEmailCategoryDialog: action.show,
                editEmailCategory: action.category !== undefined ? action.category : state.editEmailCategory
            };

        case REMOVE_GREYLISTED_EMAILS:
            return {
                ...state,
                emailHeaders: state.emailHeaders.filter(eh => !action.emailServerIds.includes(eh.ServerId || "-"))
            };

        case SHOW_REMIND_ME_LATER_SETTINGS_DIALOG:
            return {
                ...state,
                showRemindMeLaterSettingsDialog: action.show,
                remindMeLaterDate: state.selectedEmailHeaders[0] && action.show ? state.selectedEmailHeaders[0].Reminder?.ReminderTime : state.remindMeLaterDate,
            };
        case SET_REMIND_ME_LATER_DATE:
            return {...state, remindMeLaterDate: action.remindMeLaterDate};
        case SET_REMIND_ME_LATER_DATE_ON_EMAIL:
            const reminderTime = calculateDateFromRelativeDateOption(action.remindMeLaterDate);
            if (!isEmailContent(state.itemContent)) return state;
            return modifyEmailContentInThread(state, action.emailIds, {Reminder: reminderTime ? {Id: 0, ReminderTime: reminderTime} : undefined});
        case SHOW_ARCHIVE_SETTINGS_DIALOG:
            if (!isEmailContent(state.itemContent)) return state;
            const emailThread = state.itemContent;
            return {
                ...state,
                showArchiveSettingsDialog: action.show,
                // TODO: archive date!!
                autoArchiveDate: action.show ? emailThread.Emails.find(e => e.Id === emailThread.Id)?.AutoArchiveDateTime! : state.autoArchiveDate,
            };
        case SET_AUTO_ARCHIVE_DATE:
            return {...state, autoArchiveDate: action.archiveDate};
        case SET_AUTO_ARCHIVE_DATE_ON_EMAIL:
            return modifyEmailContentInThread(state, action.emailIds, {AutoArchiveDateTime: action.autoArchiveDate});
        case SET_CONTEXT_ATTACHMENT:
            return {...state, contextAttachment: action.attachment};
    }
    return state;
}

export function modifyEmailContentInThread(state: ItemState, emailIds: number[], changes: Partial<FullEmailContent>): ItemState {
    if (!isEmailContent(state.itemContent) || !state.itemContent.Emails.some(e => emailIds.includes(e.Id))) {
        return state;
    }
    const emailThread = state.itemContent;
    return {
        ...state,
        itemContent: {
            ...emailThread,
            Emails: emailThread.Emails.map(e => emailIds.includes(e.Id)
                ? {
                    ...e,
                    ...changes,
                }
                : e)
        },
    };
}

function handleGetEmailSourceAsync(state: ItemState, action: GetEmailSourceAsyncAction): ItemState {
    if (action.emailSource) {
        return {
            ...state,
            itemContent: action.emailSource,
            isLoadingContent: false,
            selectedAttachmentId: undefined,
            imageContent: undefined,
            inlineContent: undefined,
        };
    } else if (action.error) {
        console.error("Error getting email source: " + action.error.message);
        return {...state, isLoadingContent: false};
    }
    return {
        ...state,
        selectedGreylistedEmailId: !action.request?.OutboxItem ? action.request?.EmailUid : undefined,
        isLoadingContent: !!action.request?.EmailId,
        itemContent: LoadingItemContent,
    };
}

function handleComposeEmailAction(state: ItemState, draftEmail: DbDraftEmail | undefined): ItemState {
    if (!draftEmail || draftEmail.IsNew || !draftEmail.IsDraft) {
        return state;
    }
    return {
        ...state,
        draftEmailHeaders: removeDuplicates([
            EmailActions.convertDraftToEmailHeader(draftEmail),
            ...state.draftEmailHeaders
        ], e => e.LocalId),
    };
}

function handleSearchResults(state: ItemState, results?: EmailDataSourceResult): ItemState {
    // TODO: handle other types of search results and search result offsets
    if (results) {
        // TODO: handle other types of results other than emails
        const newHeaders = [...state.emailHeaders];
        newHeaders.splice(results.offset, results.pageSize, ...results.emails);

        return {...state, emailHeaders: newHeaders, allEmailHeadersLoaded: results.lastEmail, itemLoadingInProgress: false};
    }

    return {...state, emailHeaders: [], allEmailHeadersLoaded: true, itemLoadingInProgress: true};
}

function deselectModifiedItems(state: ItemState, changedItems: ItemChangeListDto): ItemContent {
    const itemContent = state.itemContent;

    if (itemContent && isAccountContent(itemContent)) {
        const accountChanges = changedItems.Accounts;
        if (accountChanges) {
            const clientId = itemContent.clientId;

            const allChangedAccounts = [...(accountChanges.Changed || [])];

            const selectedAccount = allChangedAccounts.find(a => a.ClientId === clientId);
            if (selectedAccount) {
                return NoItemContent;
            }
        }
    }
    return itemContent;
}

function handleEmailSyncChanges(state: ItemState, existingHeaders: EmailHeader[], folderState: FolderState, changes: EmailChangesDto | null): EmailHeader[] {

    if (!changes || (!changes.Changed && !changes.DeletedIds) || !folderState.selectedFolderId) {
        return existingHeaders;
    }

    const rawChanged = (changes.Changed ?? []);

    const Changed = rawChanged.filter(c => c.FolderId === folderState.selectedFolderId);
    const DeletedIds = [...(changes.DeletedIds ?? []), ...rawChanged.filter(c => c.FolderId !== folderState.selectedFolderId).map(c => c.Id)]

    if (isAndroidApp()) {
        for (const header of rawChanged) {
            if (header.Read) {
                androidApp.emailMarkedAsRead(header.Id);
            }
        }
        for (const deletedId of changes.DeletedIds ?? []) {
            androidApp.emailDeleted(deletedId);
        }
    }

    const filteredChanges: ItemChangesDto<EmailHeaderDto> = {Changed, DeletedIds};
    const result = applySyncChanges(existingHeaders, filteredChanges, convertEmailHeader);

    sortEmailHeaders(result);

    return result;
}

function removeEmailsFromList(state: ItemState, folderState: FolderState, emailIdsToRemove: number[]) {
    // TODO: the email should really be moved into the correct folder
    return {
        ...state,
        selectedEmailHeaders: [],
        itemContent: NoItemContent,
        emailHeaders: state.emailHeaders.filter(e => !emailIdsToRemove.includes(e.Id)),
    };
}

function applyRemoteOperationLocally(state: ItemState, folderState: FolderState, remoteOperation: RemoteOperationDto): ItemState {

    if (remoteOperation.SaveDraftEmail) {
        // The email has been sent!
        const draftEmail = remoteOperation.SaveDraftEmail.Email;
        return {...state, draftEmailHeaders: state.draftEmailHeaders.filter(e => e.LocalId !== draftEmail.Uid)};
    }

    const operation = remoteOperation.ManageEmails;
    if (!operation) {
        return state;
    }
    if (operation.MarkAsRead !== undefined) {
        const changes = {Read: operation.MarkAsRead ? 1 : 0};
        return applyChangeToEmailContentAndHeader(state, operation.EmailIds, changes, changes);
    }
    if (operation.Flag !== undefined) {
        const changes = {Flagged: operation.Flag ? 1 : 0};
        return applyChangeToEmailContentAndHeader(state, operation.EmailIds, changes, changes);
    }
    if (operation.Pin !== undefined) {
        const changes = {Pinned: operation.Pin};
        return applyChangeToEmailContentAndHeader(state, operation.EmailIds, changes, changes);
    }

    if (operation.Delete || operation.MarkAsJunk !== undefined || operation.MoveToFolderId) {
        return removeEmailsFromList(state, folderState, operation.EmailIds);
    }

    if (operation.NewSubject) {
        const changes = {Subject: operation.NewSubject};
        return applyChangeToEmailContentAndHeader(state, operation.EmailIds, changes, changes);
    }

    if (operation.Category || operation.Category === "") {
        const changes = {Category: operation.Category};
        return applyChangeToEmailContentAndHeader(state, operation.EmailIds, changes, changes);
    }

    if (operation.RemindMeDate) {
        const {RemindMeDate} = operation;
        const changes: Partial<EmailHeaderDto> = {
            Reminder: RemindMeDate?.Date ? {Id: 0, ReminderTime: RemindMeDate.Date} : undefined,
        };
        return applyChangeToEmailContentAndHeader(state, operation.EmailIds, changes, changes);
    }

    return state;
}

function showCalendarEntry(itemState: ItemState, calendarState: CalendarState, action: ShowCalendarEntryAction): ItemState {
    if (action.calendarUid === SERIES_CALENDAR_UID) {
        const calendar = calendarState.calendars.find(c => c.Id === action.instanceId);
        if (calendar?.StartDateTime && calendar.EndDateTime) {
            const calendarSeries: CalendarEntryData = {
                calendar,
                instanceId: 0,
                recurrence: null,
                intervalLocal: Interval.fromDateTimes(calendar.StartDateTime.toLocal(), calendar.EndDateTime.toLocal()) as NonNullInterval,
                intervalUtc: Interval.fromDateTimes(calendar.StartDateTime.toUTC(), calendar.EndDateTime.toUTC()) as NonNullInterval,
                colStart: 0,
                colSpan: 0,
                row: 0,
            };
            return {...itemState, itemContent: calendarSeries};
        }
    }

    const calendarEntry = calendarState.viewData.rows
        .flatMap(r => r.entries)
        .find(c => c.instanceId === action.instanceId && c.calendar.Uid === action.calendarUid);

    return {...itemState, itemContent: calendarEntry || NoItemContent};
}

function findAvailableCategories(emailHeaders: EmailHeader[]) {
    const allCategories = emailHeaders
        .filter(h => h.Category)
        .map(h => h.Category!);

    return [...new Set<string>(allCategories)]
        .sort((a, b) => a.localeCompare(b))
}
