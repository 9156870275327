import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AccountsState, initialAccountsState} from "../state/AccountState";
import {DecryptedGateKeeperAccount} from "../domain/DecryptedGateKeeperAccount";
import {DbGateKeeperAccount} from "../db/DbSchema";
import {FullEmailThreadContent} from "../domain/EmailContentDto";
import {SyncActions} from "./SyncSlice";
import {applySyncChanges} from "../reducers/SyncHelper";
import {BEGIN_SELECT_ACCOUNT, CANCEL_PASSWORD_ENTRY, SELECT_ACCOUNT} from "../actions/ItemActions";
import {GateKeeperField} from "../domain/GateKeeperField";
import {getCreditCardType} from "../util/CreditCardUtil";
import {PgpKey} from "../domain/PgpKey";
import {FileDto} from "../services/protected/dto/FileDto";
import {extractEmailAddresses} from "../util/Formatters";
import {ItemChangeListDto} from "../services/messages/ItemChangeListDto";

export interface SetPgpKeysPayload {
    keys: PgpKey[];
    loadedIfEmpty: boolean;
}

export interface UpdateAccountHistoryVersionPayload {
    updatedVersion: DecryptedGateKeeperAccount;
    index: number;
}

export interface AccountNeedsPasswordPayload {
    record: DbGateKeeperAccount | FullEmailThreadContent | FileDto;
    errorMessage: string;
    keyName: string | null;
}

export interface UpdateAccountFieldPayload {
    updatedField: GateKeeperField;
    index: number;
}

export const accountsSlice = createSlice({
    name: "accounts",
    initialState: initialAccountsState,
    reducers: {
        setSecretKeys(state, {payload}: PayloadAction<SetPgpKeysPayload>) {
            state.secretKeys = payload.keys;
            state.secretKeysLoaded = payload.loadedIfEmpty || payload.keys.length > 0;
        },
        setPgpKeys(state, {payload}: PayloadAction<SetPgpKeysPayload>) {
            state.pgpKeys = payload.keys;
            state.pgpKeysLoaded = payload.loadedIfEmpty || payload.keys.length > 0;
            postProcessPgpKeys(state);
        },
        setAccountsPassword(state, {payload}: PayloadAction<string>) {
            state.password = payload;
            state.passwordRequired = false;
        },
        accountsLoaded(state, {payload}: PayloadAction<DbGateKeeperAccount[]>) {
            state.accounts = payload;
        },
        showAccountHistory(state, {payload}: PayloadAction<DecryptedGateKeeperAccount[] | null>) {
            state.accountHistory = payload;
        },
        updateAccountHistoryVersion(state, {payload}: PayloadAction<UpdateAccountHistoryVersionPayload>) {
            handleUpdateAccountHistoryVersion(state as AccountsState, payload);
        },
        itemNeedsPassword(state, {payload}: PayloadAction<AccountNeedsPasswordPayload>) {
            state.passwordRequired = true;
            state.passwordRequiredForItem = payload.record;
            state.passwordError = payload.errorMessage;
            state.keyDescription = payload.keyName;
        },
        setEditAccount(state, {payload}: PayloadAction<DecryptedGateKeeperAccount | null>) {
            state.editAccount = payload;
        },
        removeEditAccountField(state, {payload: index}: PayloadAction<number>) {
            if (state.editAccount) {
                state.editAccount.fields = state.editAccount.fields.filter((f, i) => i !== index);
            }
        },
        updateAccountField(state, {payload}: PayloadAction<UpdateAccountFieldPayload>) {
            if (state.editAccount) {
                state.editAccount.fields[payload.index] = payload.updatedField;
                const field = state.editAccount.fields[payload.index];
                if (field.type === "CardNumber") {
                    state.editAccount.image = "card-" + getCreditCardType(field.value);
                }
            }
        },
        clearItemContent(state) {
            state.editAccount = null;
        },
    },
    extraReducers: builder => builder
        .addCase(SyncActions.syncResponseReceived, handleSyncResponse)
        .addMatcher(a => a.type === SELECT_ACCOUNT || a.type === BEGIN_SELECT_ACCOUNT, state => {
            state.passwordRequired = false;
            state.editAccount = null;
            state.accountHistory = null;
        })
        .addMatcher(a => a.type === CANCEL_PASSWORD_ENTRY, state => {
            state.passwordRequired = false;
        })
    //case ADD_REMOTE_OPERATION:
// TODO: apply the changes locally
// return state;
// case REMOVE_REMOTE_OPERATIONS:
//     // TODO: apply the changes locally
//     return state;
});

function handleUpdateAccountHistoryVersion(state: AccountsState, payload: UpdateAccountHistoryVersionPayload) {
    if (!state.accountHistory || state.accountHistory[0].entityId !== payload.updatedVersion.entityId) {
        return state;
    }

    state.accountHistory[payload.index] = payload.updatedVersion;
}

function postProcessPgpKeys(state: AccountsState) {
    state.pgpKeyAddresses = state.pgpKeys
        .flatMap(k => k.key.getUserIds()
            .flatMap(id => extractEmailAddresses(id.toLowerCase())));
}

function handleSyncResponse(state: AccountsState, {payload: items}: PayloadAction<ItemChangeListDto>) {
    state.pgpKeys = state.pgpKeysLoaded
        ? applySyncChanges<PgpKey, PgpKey>(state.pgpKeys, items.DecodedPublicKeys, a => a, k => !k.isPrivate)
        : state.pgpKeys;

    state.secretKeys = state.secretKeysLoaded
        ? applySyncChanges<PgpKey, PgpKey>(state.secretKeys, items.DecodedSecretKeys, a => a, k => k.isPrivate)
        : state.secretKeys;

    state.accounts = applySyncChanges(state.accounts, items.Accounts, a => a);

    postProcessPgpKeys(state);
}

export namespace AccountsActions {
    export const {
        itemNeedsPassword,
        accountsLoaded,
        clearItemContent,
        setAccountsPassword,
        setEditAccount,
        removeEditAccountField,
        setPgpKeys,
        setSecretKeys,
        showAccountHistory,
        updateAccountField,
        updateAccountHistoryVersion,
    } = accountsSlice.actions;
}
