import React, {MouseEvent, useState} from "react";
import {EmailHeader} from "../../services/messages/EmailHeaderDto";
import {EmailActions} from "../../actions/EmailActions";
import {isOutgoingFolder, SearchFolderId} from "../../reducers/FolderReducer";
import "./EmailHeaderView.scss";
import {AppState} from "../../AppState";
import {FolderTypeEnum} from '../../domain/Folder';
import {HotKeys} from 'react-hotkeys';
import {formatSubject} from "../../util/Formatters";
import {formatFromAddresses} from "./EmailHeaderGridColumns";
import {EmailHeaderStatusBar} from "./EmailHeaderStatusBar";
import {QuickFilterOptionList} from "../common/QuickFilterOption";
import {QuickFilterMode} from "../../actions/QuickFilterActionTypes";
import {useAppDispatch, useAppSelector, useAppStore} from "../../hooks/ReduxHooks";
import classNames from "classnames";
import {EmailLocation} from "../../locations/EmailLocation";
import {ContentLocation} from "../../locations/ContentLocation";
import {EmailContextMenu} from "./EmailContextMenu";
import {SimpleGrid} from "../common/grid/SimpleGrid";
import {RelativeDateTime} from "../common/RelativeDateTime";
import {VerbIcon} from "./VerbIcon";
import {isTearOffWindow} from "../../App";
import NotificationsPausedIcon from "@mui/icons-material/NotificationsPaused";
import {SimpleGridHeader} from "../common/grid/SimpleGridHeader";
import {ItemActions} from "../../actions/ItemActions";
import LockIcon from "@mui/icons-material/Lock";
import {SearchOptionsView} from "./SearchOptionsView";
import {KnownCategoriesMap} from "../../domain/KnownCategories";
import {EmptyEmailSearch} from "../common/EmptyEmailSearch";

interface EmailHeaderRowProps {
    header: EmailHeader;
    isOutgoing: boolean;
}

interface EmailHeaderFolderProps {
    header: EmailHeader;
}

const ATTACHMENT_SVG = (
    <svg focusable="false" viewBox="0 0 24 24">
        <path
            d="M16.5 6v11.5c0 2.21-1.79 4-4 4s-4-1.79-4-4V5c0-1.38 1.12-2.5 2.5-2.5s2.5 1.12 2.5 2.5v10.5c0 .55-.45 1-1 1s-1-.45-1-1V6H10v9.5c0 1.38 1.12 2.5 2.5 2.5s2.5-1.12 2.5-2.5V5c0-2.21-1.79-4-4-4S7 2.79 7 5v12.5c0 3.04 2.46 5.5 5.5 5.5s5.5-2.46 5.5-5.5V6h-1.5z"/>
    </svg>
);

export const TrustShield = () => (
    <span className="icon from">
        <svg focusable="false" viewBox="0 0 24 24">
            <path
                d="M12 1L3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4zm-2 16l-4-4 1.41-1.41L10 14.17l6.59-6.59L18 9l-8 8z"/>
        </svg>
    </span>
);

function filterEmailHeaders(appState: AppState, filterMode: QuickFilterMode, searchText: string, categories: string[]) {
    const {folderState, itemState} = appState;

    const emailHeaders = folderState.selectedFolder?.type === FolderTypeEnum.Drafts
        ? itemState.draftEmailHeaders
        : itemState.emailHeaders;

    let filteredEmailHeaders = emailHeaders;
    switch (filterMode) {
        case "Unread":
            filteredEmailHeaders = emailHeaders.filter(h => !h.Read);
            break;
        case "Flagged":
            filteredEmailHeaders = emailHeaders.filter(h => h.Flagged || h.Pinned);
            break;
    }

    filteredEmailHeaders = categories.length > 0
        ? filteredEmailHeaders.filter(e => e.Category && categories.includes(e.Category))
        : filteredEmailHeaders;

    return searchText.length === 0
        ? filteredEmailHeaders
        : filteredEmailHeaders.filter(h =>
            h.From?.toLowerCase().includes(searchText)
            || h.Subject?.toLowerCase().includes(searchText)
            || h.To?.toLowerCase().includes(searchText)
            || h.Category?.toLowerCase().includes(searchText));
}

function RenderEmailFolderHeader({header}: EmailHeaderFolderProps) {
    const folder = useAppSelector(({folderState}) => folderState.folderList.find(f => f.folderId === header.FolderId));
    return <span className="header-folder">{folder?.name}</span>;
}

function getCategoryText(category: string | null) {
    if (!category) return category;
    return KnownCategoriesMap[category] ?? category;
}

function RenderEmailHeaderRow({header, isOutgoing}: EmailHeaderRowProps) {
    const dispatch = useAppDispatch();

    const isMobile = useAppSelector(({system}) => system.isMobile);
    const isSearchFolder = useAppSelector(({folderState}) => folderState.selectedFolderId === SearchFolderId);

    async function onToggleFlag(e: MouseEvent) {
        await EmailActions.setEmailFlags(dispatch, [header], !header.Flagged);
        e.stopPropagation();
    }

    async function onTogglePinned(e: MouseEvent) {
        await EmailActions.setEmailPinned(dispatch, [header], !header.Pinned);
        e.stopPropagation();
    }

    const displayCategory = getCategoryText(header.Category);

    if (isMobile) {
        return (<>
            <div>
                <div className="header-from">
                    {formatFromAddresses(header, isOutgoing)}
                    {header.ShowTrustShield && <TrustShield/>}
                </div>
                <div>
                    <svg focusable="false" viewBox="0 0 24 24" className={classNames("header-flag", "icon", "flag", {"pinned": header.Pinned})}
                         onClick={e => onTogglePinned(e)}>
                        <path fillRule="evenodd" d="M16 9V4h1c.55 0 1-.45 1-1s-.45-1-1-1H7c-.55 0-1 .45-1 1s.45 1 1 1h1v5c0 1.66-1.34 3-3 3v2h5.97v7l1 1 1-1v-7H19v-2c-1.66 0-3-1.34-3-3z"/>
                    </svg>
                    <svg focusable="false" viewBox="0 0 24 24" className={classNames("header-flag", "icon", "flag", {"flagged": header.Flagged})}
                         onClick={e => onToggleFlag(e)}>
                        <path d="M14.4 6L14 4H5v17h2v-7h5.6l.4 2h7V6z"/>
                    </svg>
                </div>
                <div className="header-date"><RelativeDateTime dateTime={header.Date}/></div>
            </div>
            <div>
                <div className="header-subject">{formatSubject(header)}</div>
                {header.AttachmentCount > 0 && <div className="header-attachment">{ATTACHMENT_SVG}</div>}
                <div className={classNames("header-category", "email-category", "category-" + header.Category)}>{header.Category}</div>
            </div>
        </>)
    } else {
        return (<>
            <div className="header-attachment">{
                header.AttachmentCount > 0
                    ? ATTACHMENT_SVG
                    : (header.Reminder
                        ? <NotificationsPausedIcon style={{marginTop: "3px"}}/>
                        : (header.MessageClass === "IPM.Note.SMIME" ? <LockIcon/> : <VerbIcon verb={header.LastVerb}/>))
            }</div>
            <div className="header-from">
                {formatFromAddresses(header, isOutgoing)}
                {header.ShowTrustShield && <TrustShield/>}
            </div>
            <div>
                {(!header.Flagged || header.Pinned) && <svg focusable="false" viewBox="0 0 24 24" className={classNames("header-flag", "icon", "flag", {"pinned": header.Pinned})} style={{position: header.Pinned ? "static" : "absolute"}}
                     onClick={e => onTogglePinned(e)}>
                    <path fillRule="evenodd" d="M16 9V4h1c.55 0 1-.45 1-1s-.45-1-1-1H7c-.55 0-1 .45-1 1s.45 1 1 1h1v5c0 1.66-1.34 3-3 3v2h5.97v7l1 1 1-1v-7H19v-2c-1.66 0-3-1.34-3-3z"/>
                </svg>}
                {!header.Pinned && <svg focusable="false" viewBox="0 0 24 24" className={classNames("header-flag", "icon", "flag", {"flagged": header.Flagged})}
                     onClick={e => onToggleFlag(e)}>
                    <path d="M14.4 6L14 4H5v17h2v-7h5.6l.4 2h7V6z"/>
                </svg>}
            </div>
            <div className="header-subject">
                {isSearchFolder && <RenderEmailFolderHeader header={header}/>}
                <span
                    className={classNames("header-category", "email-category", "category-" + header.Category?.replace(" ", "_"), {"special-category": displayCategory !== header.Category})}
                    title={header.Category ?? undefined}>
                    {displayCategory}
                </span>
                <span>{formatSubject(header)}</span>
            </div>
            <div className="header-date"><RelativeDateTime dateTime={header.Date}/></div>
        </>);
    }
}

export const EmailHeaderView = () => {
    const store = useAppStore();
    const dispatch = useAppDispatch();

    const isMobile = useAppSelector(({system}) => system.isMobile);

    const selectedCategories = useAppSelector(s => s.itemState.selectedCategoriesFilter);

    const [searchText, setSearchText] = useState<string>("");
    const [filterMode, setFilterMode] = useState<QuickFilterMode>("All");

    const lazyLoadDataSource = useAppSelector(({folderState}) => folderState.lazyLoadDataSource);
    const isSearchResults = useAppSelector(s => s.folderState.selectedFolderId === -1);

    const emailHeaders = useAppSelector(state =>
        lazyLoadDataSource
            ? state.itemState.emailHeaders
            : filterEmailHeaders(state, filterMode, searchText, selectedCategories));

    const selectedItemSelector = ({itemState}: AppState) => itemState.selectedEmailHeaders;

    const selectedFolderType = useAppSelector(({folderState}) => folderState.selectedFolder?.type);

    const isOutgoing = isOutgoingFolder(selectedFolderType);

    if (isTearOffWindow) return null;

    console.log("Render email header view");

    function handleSelectQuickFilterMode(newMode: QuickFilterMode) {
        setFilterMode(newMode);
    }

    function handleQuickSearchTextChange(searchText: string) {
        setSearchText(searchText.toLowerCase());
    }

    function onUpdateSelection(newSelection: EmailHeader[]) {
        dispatch(EmailActions.selectEmailHeaders(newSelection));
        if (newSelection.length === 0) {
            ContentLocation.clearItemContent();
        }
    }

    function onSelectAll(selected: boolean) {
        if (selected) {
            dispatch(EmailActions.selectEmailHeaders(emailHeaders));
        } else {
            dispatch(EmailActions.selectAllEmails(false));
        }
    }

    function onItemClick(header: EmailHeader, newSelection: EmailHeader[]) {
        if (header.IsDraft) {
            EmailLocation.openDraftEmail(header);
        } else if (newSelection.length === 1) {
            ContentLocation.selectEmailHeader(header);
        }
    }

    const keyHandlers = {
        DELETE: () => EmailActions.deleteSelectedEmails(dispatch, store.getState()),
        UP: () => {
            const selection = store.getState().itemState.selectedEmailHeaders;
            if (selection.length === 1) {
                const index = emailHeaders.indexOf(selection[0]);
                if (index > 0) ContentLocation.selectEmailHeader(emailHeaders[index - 1]);
            }
        },
        DOWN: () => {
            const selection = store.getState().itemState.selectedEmailHeaders;
            if (selection.length === 1) {
                const index = emailHeaders.indexOf(selection[0]);
                if (index < emailHeaders.length - 1) ContentLocation.selectEmailHeader(emailHeaders[index + 1]);
            }
        }
    };

    // TODO: move this to the reducer!!!
    // const dateRangeCategories: Record<string, EmailHeader[]> = {};
    // for (const emailHeader of emailHeaders) {
    //     const category = emailHeader.DateRangeCategory;
    //     if (!dateRangeCategories[category]) {
    //         dateRangeCategories[category] = [];
    //     }
    //     dateRangeCategories[category].push(emailHeader);
    // }
    const itemHeight = isMobile ? 60 : 30;

    return (
        <HotKeys className="email-view content-item-list" handlers={keyHandlers}>
            {!lazyLoadDataSource && (<QuickFilterOptionList onSelectQuickFilterMode={handleSelectQuickFilterMode}
                                                            onQuickSearchTextChange={handleQuickSearchTextChange}/>)}
            {lazyLoadDataSource && isSearchResults && <SearchOptionsView/>}

            <EmailHeaderStatusBar/>

            {/*{Object.entries(dateRangeCategories).map(([category, categoryEmails]) => {*/}
            {/*    */}
            {/*    return <Accordion key={category}>*/}
            {/*        <AccordionSummary>{category}</AccordionSummary>*/}
            {/*        <AccordionDetails style={{height: (30 + itemHeight * categoryEmails.length) + "px"}}>*/}
            <SimpleGrid key={1}
                        items={emailHeaders}
                        segmentBy={"DateRangeCategory"}
                        selectedItemSelector={selectedItemSelector}
                        onUpdateSelection={onUpdateSelection}
                        onSelectAll={onSelectAll}
                        onItemClick={onItemClick}
                        rowIdExtractor={header => header.LocalId || header.Id}
                        rowRenderer={header => <RenderEmailHeaderRow header={header} isOutgoing={isOutgoing}/>}
                        rowClassRules={header => ({
                            "mobile": isMobile,
                            "unread": !header.Read,
                            "important": header.Importance === "High"
                        })}
                        itemHeight={itemHeight}
                        itemDataSource={lazyLoadDataSource}
                        allItemsLoadedSelector={state => state.itemState.allEmailHeadersLoaded}
                        contextMenu={ref => <EmailContextMenu elementRef={ref}/>}
                        emptyGridContent={(searchText || isSearchResults) ? <EmptyEmailSearch/> : undefined}
            >
                <div className="header-attachment">&nbsp;</div>
                <div className="header-from">{isOutgoing ? "To" : "From"}</div>
                <div className="header-flag">&nbsp;</div>
                <SimpleGridHeader className="header-subject"
                                  filterSelector={state => state.itemState.availableCategories}
                                  selectedFilterOptionsSelector={s => s.itemState.selectedCategoriesFilter}
                                  selectFilterOptionAction={ItemActions.setSelectedCategoryFilters}
                >
                    Subject
                </SimpleGridHeader>
                <div className="header-date">Date</div>
            </SimpleGrid>
            {/*        </AccordionDetails>*/}
            {/*    </Accordion>*/}
            {/*})}*/}


        </HotKeys>
    );
};
