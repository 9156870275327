import classNames from "classnames";
import React from "react";
import {EmailActions} from "../../actions/EmailActions";
import {useAppDispatch} from "../../hooks/ReduxHooks";

interface EmailCategoryProps {
    category: string | null;
}

export const EmailCategory = ({category}: EmailCategoryProps) => {

    const dispatch = useAppDispatch();

    function onChangeCategory() {
        dispatch(EmailActions.showEmailCategoryDialog(category || ""));
    }

    return (
        <span className={classNames("email-category", "category-" + category?.replace(" ", "_"), "selectable", "pointer")}
              onClick={onChangeCategory}>
            {category}
        </span>
    )
}