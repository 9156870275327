import React, {FocusEventHandler, KeyboardEventHandler} from "react";
import {AutocompleteRenderInputParams, Paper, TextField} from "@mui/material";
import classNames from "classnames";
import styled from "@emotion/styled";
import Autocomplete from "@mui/material/Autocomplete";

const AutoCompleteOptionSpan = styled.span`
    font-size: 10pt;
    display: block;

    span.name {
        display: block;
        font-weight: bold;
    }
`;

interface AutocompleteOption {
    title: string;
    value: string;
}

interface EmailAutoCompleteProps {
    label?: string;
    options: AutocompleteOption[];
    value: string;
    autoFocus?: boolean;
    freeSolo?: boolean;
    className?: string;
    disableClearable?: boolean;
    setValue: (newValue: string) => void;
    onFocus?: FocusEventHandler<HTMLInputElement>;
    onKeyPress?: KeyboardEventHandler;
    fullWidth?: boolean;
    inputType?: string;
}

function getAutoCompleteLabel(option: AutocompleteOption | string): string {
    return isAutocompleteOption(option) ? option.title : option ?? "";
}

function isAutocompleteOption(value: AutocompleteOption | string | null): value is AutocompleteOption {
    return (value as AutocompleteOption).title !== undefined;
}

const RenderSimpleAutocomplete = (props: EmailAutoCompleteProps) => {
    const [open, setOpen] = React.useState(false);

    function handleChange(event: React.ChangeEvent<{}>, newValue: AutocompleteOption | string | null) {
        console.log("handleChange", event, newValue);
        if (newValue === null && props.freeSolo) {
            props.setValue("");
        } else if (newValue !== undefined && newValue !== null) {
            const newStringValue = isAutocompleteOption(newValue) ? newValue.value : newValue;
            props.setValue(newStringValue);
        }
    }

    function renderOption(props: any, option: AutocompleteOption | string) {
        return (
            <AutoCompleteOptionSpan {...props}>
                <span className="name">{(typeof option === "string") ? option : option.title}</span>
            </AutoCompleteOptionSpan>
        );
    }

    function renderInput(params: AutocompleteRenderInputParams) {
        return (
            <TextField
                {...params}
                label={props.label}
                margin="dense"
                fullWidth
                autoFocus={props.autoFocus}
                size="small"
                InputProps={{...params.InputProps, type: props.inputType}}
            />);
    }

    return (
        <Autocomplete
            className={classNames("auto-complete", props.className)}
            autoFocus={props.autoFocus}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            onChange={handleChange}
            options={props.options}
            freeSolo={props.freeSolo}
            disableClearable={props.disableClearable}
            value={props.value}
            getOptionLabel={getAutoCompleteLabel}
            PaperComponent={params => (<Paper {...params} elevation={8}/>)}
            renderOption={renderOption}
            renderInput={renderInput}
            onFocus={(e) => {
                if (props.onFocus) props.onFocus(e as any);
            }}
            onKeyPress={props.onKeyPress}
            fullWidth={props.fullWidth}
        />);
};

export default React.memo(RenderSimpleAutocomplete);
