import {FullEmailContent} from "../domain/EmailContentDto";
import _unescape from 'lodash/unescape';
import _ from "lodash";
import {dateTimeFromTicks} from "../util/DateHelper";

const BlockQuoteStyle: string = "margin: 0; padding-left: 0;";

export enum MailMutateAction {
    None,
    Reply,
    ReplyAll,
    Forward
}

export class EmailMutator {

    private readonly email: FullEmailContent;
    private readonly action: MailMutateAction;
    private readonly originalBodyText: string;

    constructor(email: FullEmailContent, action: MailMutateAction, originalBodyText: string) {
        this.email = email;
        this.action = action;
        this.originalBodyText = originalBodyText;
    }

    mutateMailInPlainText(): string {
        if (this.action === MailMutateAction.None) {
            return this.originalBodyText;
        }

        let sb = "";
        if (this.action === MailMutateAction.Forward) {
            sb += "\r\n\r\n---------- Forwarded message ----------\r\n";
        } else {
            sb += "\r\n\r\n---------- Original message -----------\r\n";
        }

        sb += "From: " + this.email.From + "\r\n";
        sb += "Date: " + this.email.Date + "\r\n";
        sb += "Subject: " + this.email.Subject + "\r\n";
        if (this.email.To && this.email.To.trim().length > 0) {
            sb += "To: " + this.email.To + "\r\n";
        }
        if (this.email.CC && this.email.CC.trim().length > 0) {
            sb += "CC: " + this.email.CC + "\r\n";
        }
        sb += "\r\n";
        sb += this.originalBodyText;
        return sb;
    }

    mutateMailInHtml(): string {
        if (this.action === MailMutateAction.None) {
            return this.originalBodyText;
        }

        return "<br/><br/>" +
            "<blockquote class=\"email-jrlh-quote\" style=\"" + BlockQuoteStyle + "\">" +
            "<div>" +
            this.appendField("From", this.email.From) +
            this.appendField("Date", dateTimeFromTicks(this.email.Date).toFormat("EEE, dd MMM yyyy HH:mm:ss ZZZ")) +
            this.appendField("Subject", _unescape(this.email.Subject)) +
            this.appendField("To", this.email.To) +
            this.appendField("CC", this.email.CC) +
            "</div>" +
            "<br/>"
            + this.originalBodyText
            + "</blockquote>";
    }

    private appendField(fieldName: string, value: any): string {
        if (value && value.toString().trim().length > 0) {
            const encodedValue = _.escape(value.toString());
            return `<span style="font-weight:bold;">${fieldName}:</span>&nbsp;${encodedValue}<br/>`;
        }
        return "";
    }
}

