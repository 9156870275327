import {AppDispatch} from "../AppStore";
import {AppState} from "../AppState";
import {EmailActions} from "../actions/EmailActions";
import {ItemActions} from "../actions/ItemActions";
import {EmailRuleDto} from "../services/rules/dto/EmailRuleDto";
import {RulesService} from "../services/rules/RulesService";
import {isEmailContent} from "../domain/ItemContent";
import {EMAIL_FROM_CONDITION, EMAIL_SUBJECT_CONDITION, EMAIL_TO_CONDITION, EmailToCondition} from "../services/rules/dto/EmailRuleConditionDto";
import {extractEmailAddresses} from "../util/Formatters";
import {MOVE_TO_FOLDER_ACTION} from "../services/rules/dto/EmailRuleActionDto";
import Folder, {FolderTypeEnum} from "../domain/Folder";
import {FullEmailContent} from "../domain/EmailContentDto";

function createRuleFromCurrentEmail(email: FullEmailContent, folders: Folder[]) {
    const toConditions: EmailToCondition[] = extractEmailAddresses(email.To)
        .map(address => ({Type: EMAIL_TO_CONDITION, Address: address, IncludeCC: false}));

    const newRule: EmailRuleDto = {
        Id: -1,
        Rank: -1,
        Conditions: [
            {Type: EMAIL_FROM_CONDITION, Address: extractEmailAddresses(email.From)[0]},
            {Type: EMAIL_SUBJECT_CONDITION, Text: email.Subject, MatchPartial: false, MatchCase: false, IsRegex: false},
            ...toConditions,
        ],
        Actions: [
            {Type: MOVE_TO_FOLDER_ACTION, FolderId: folders.find(f => f.type === FolderTypeEnum.Inbox)?.folderId || -1},
        ],
        OrConditions: false,
        StopProcessing: false,
        Name: (email.Subject || "").replace(/Fw:|Fwd:|Re:/gi, "").trim(),
        LastUsedDateTime: undefined,
    };
    return newRule;
}

export namespace RulesLocation {
    export async function handleViewEmailRule(state: AppState, newRuleId: number, dispatch: AppDispatch) {

        const rules =
            state.rulesState.rules.length === 0
                ? await RulesService.updateRules(dispatch)
                : state.rulesState.rules;

        const rule = rules.find(r => r.Id === newRuleId);

        if (!rule && newRuleId === -2 && isEmailContent(state.itemState.itemContent)) {
            // TODO: rule creation from current email!
            const emailThread = state.itemState.itemContent;
            const newRule = createRuleFromCurrentEmail(emailThread.Emails.find(e => e.Id === emailThread.Id)!, state.folderState.folderList);

            dispatch(ItemActions.showItemContent(newRule));

        } else if (!rule && newRuleId === -1) {
            const newRule: EmailRuleDto = {
                Id: -1,
                Rank: -1,
                Actions: [],
                Conditions: [],
                OrConditions: true,
                StopProcessing: false,
                Name: "New Rule",
                LastUsedDateTime: undefined,
            };
            dispatch(ItemActions.showItemContent(newRule));
        } else {
            dispatch(rule ? ItemActions.showItemContent(rule) : EmailActions.clearItemContent());
        }
    }
}
