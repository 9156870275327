import {EmailHeader} from "../services/messages/EmailHeaderDto";
import {FullEmailThreadContent} from "../domain/EmailContentDto";
import RemoteOperationDto from "../services/messages/RemoteOperationDto";
import {AttachmentDto} from "../domain/AttachmentDto";
import {EmailAnalysisDto} from '../services/messages/responses/EmailAnalysisDto';
import {OutgoingEmailDto} from '../services/messages/OutgoingEmailDto';
import {AsyncActionMode} from './AsyncHelper';
import {EmailSourceDto} from '../domain/EmailSourceDto';
import {EmailSourceRequestDto} from '../services/messages/requests/EmailSourceRequestDto';
import {AttachmentContentDto} from "../domain/AttachmentContentDto";
import {RelativeDateTimeOption} from "../domain/RelativeDateTimeOption";

export type LocationItemType =
    "email" | "emailthread" |"source" | "analyze" | "rawsource" | "greylisted" | "outbox"
    | "contact" | "editcontact"
    | "calendar" | "editcalendar"
    | "account" | "editaccount"
    | "pgpkey"
    | "rule"
    | "photo" | "photofolder";

export const DRAFT_EMAIL_HEADERS_LOADED = "DRAFT_EMAIL_HEADERS_LOADED";

export interface DraftEmailHeadersLoadedAction {
    type: typeof DRAFT_EMAIL_HEADERS_LOADED;
    draftEmailHeaders: EmailHeader[];
}

export const SELECT_EMAIL_HEADERS = "SELECT_EMAIL_HEADERS";

export interface SelectEmailHeadersAction {
    type: typeof SELECT_EMAIL_HEADERS;
    emails: EmailHeader[];
}

export const SELECT_CATEGORY_FILTERS = "SELECT_CATEGORY_FILTERS";

export interface SelectCategoryFiltersAction {
    type: typeof SELECT_CATEGORY_FILTERS;
    filters: string[];
}

export const SELECT_ALL_EMAIL_HEADERS = "SELECT_ALL_EMAIL_HEADERS";

export interface SelectAllEmailHeadersAction {
    type: typeof SELECT_ALL_EMAIL_HEADERS;
    select: boolean;
}

export const SELECT_OUTGOING_EMAILS = "SELECT_OUTGOING_EMAILS";

export interface SelectOutgoingEmailsAction {
    type: typeof SELECT_OUTGOING_EMAILS;
    outgoingEmails: OutgoingEmailDto[];
}

export const GET_EMAIL_CONTENT_ASYNC = "GET_EMAIL_CONTENT_ASYNC";

export interface GetEmailContentAsyncAction {
    type: typeof GET_EMAIL_CONTENT_ASYNC;
    emailId?: number;
    content?: FullEmailThreadContent;
    error?: Error;
}

export const ANALYZE_EMAIL_ASYNC = "ANALYZE_EMAIL_ASYNC";

export interface AnalyzeEmailAsyncAction {
    type: typeof ANALYZE_EMAIL_ASYNC;
    emailId?: number;
    analysis?: EmailAnalysisDto;
    error?: Error;
}

export const GET_EMAIL_SOURCE_ASYNC = "GET_EMAIL_SOURCE_ASYNC";

export interface GetEmailSourceAsyncAction {
    type: typeof GET_EMAIL_SOURCE_ASYNC;
    request?: EmailSourceRequestDto;
    emailSource?: EmailSourceDto;
    error?: Error;
}

export const SHOW_EMAIL_HEADERS = "SHOW_EMAIL_HEADERS";

export interface ShowEmailHeadersAction {
    type: typeof SHOW_EMAIL_HEADERS;
    emailHeaders: EmailHeader[];
    allEmailHeadersLoaded: boolean;
}

export const REMOVE_REMOTE_OPERATIONS = "REMOVE_REMOTE_OPERATIONS";

export interface RemoveRemoteOperationsAction {
    type: typeof REMOVE_REMOTE_OPERATIONS;
    operationsToRemove: RemoteOperationDto[];
}

export const DELETE_DRAFT_EMAILS = "DELETE_DRAFT_EMAILS";

export interface DeleteDraftEmailsAction {
    type: typeof DELETE_DRAFT_EMAILS;
    uids: string[];
}

export const SHOW_IMAGE_ATTACHMENT = "SHOW_IMAGE_ATTACHMENT";

export interface ShowImageAttachmentAction {
    type: typeof SHOW_IMAGE_ATTACHMENT;
    attachment: AttachmentDto;
    emailId: number;
}

export const SHOW_INLINE_ATTACHMENT = "SHOW_INLINE_ATTACHMENT";

export interface ShowInlineAttachmentAction {
    type: typeof SHOW_INLINE_ATTACHMENT;
    attachment: AttachmentDto;
    isInline: boolean;
    emailId: number;
}

export const SHOW_EMAIL_CONTENT = "SHOW_EMAIL_CONTENT";

export interface ShowEmailContentAction {
    type: typeof SHOW_EMAIL_CONTENT;
}

export const SHOW_ATTACHMENT_CONTENT = "SHOW_ATTACHMENT_CONTENT";

export interface ShowAttachmentContentAction {
    type: typeof SHOW_ATTACHMENT_CONTENT;
    attachmentContent: AttachmentContentDto;
}

export const CLEAR_ITEM_CONTENT = "CLEAR_ITEM_CONTENT";

export interface ClearItemContentAction {
    type: typeof CLEAR_ITEM_CONTENT;
}

export const TOGGLE_EXPAND_CONTENT = "TOGGLE_EXPAND_CONTENT";

export interface ToggleExpandContentAction {
    type: typeof TOGGLE_EXPAND_CONTENT;
}

export const SHOW_EMAIL_CATEGORY_DIALOG = "SHOW_EMAIL_CATEGORY_DIALOG";

export interface ShowEmailCategoryDialogAction {
    type: typeof SHOW_EMAIL_CATEGORY_DIALOG;
    show: boolean;
    category?: string;
}

export const MOVE_EMAILS_BEGIN = "MOVE_EMAILS_BEGIN";

export interface BeginMoveEmailsAction {
    type: typeof MOVE_EMAILS_BEGIN;
    emails: EmailHeader[];
}

export const STOP_SENDING_EMAILS_ASYNC = "STOP_SENDING_EMAILS_ASYNC";

export interface StopSendingEmailsAsyncAction {
    type: typeof STOP_SENDING_EMAILS_ASYNC;
    action: AsyncActionMode;
    outgoingEmails?: OutgoingEmailDto[];
    uids?: string[];
    error?: Error;
}

export const REMOVE_GREYLISTED_EMAILS = "REMOVE_GREYLISTED_EMAILS";

export interface RemoveGreylistedEmailsAction {
    type: typeof REMOVE_GREYLISTED_EMAILS;
    emailServerIds: string[];
}

export const SHOW_REMIND_ME_LATER_SETTINGS_DIALOG = "SHOW_REMIND_ME_LATER_SETTINGS_DIALOG";

export interface ShowRemindMeLaterSettingsDialogAction {
    type: typeof SHOW_REMIND_ME_LATER_SETTINGS_DIALOG;
    show: boolean;
}

export const SET_REMIND_ME_LATER_DATE = "SET_REMIND_ME_LATER_DATE";

export interface SetRemindMeLaterDateAction {
    type: typeof SET_REMIND_ME_LATER_DATE;
    remindMeLaterDate: RelativeDateTimeOption;
}

export const SET_REMIND_ME_LATER_DATE_ON_EMAIL = "SET_REMIND_ME_LATER_DATE_ON_EMAIL";

export interface SetRemindMeLaterDateOnEmailAction {
    type: typeof SET_REMIND_ME_LATER_DATE_ON_EMAIL;
    remindMeLaterDate: RelativeDateTimeOption;
    emailIds: number[];
}

export const SHOW_ARCHIVE_SETTINGS_DIALOG = "SHOW_ARCHIVE_SETTINGS_DIALOG";

export interface ShowArchiveSettingsDialogAction {
    type: typeof SHOW_ARCHIVE_SETTINGS_DIALOG;
    show: boolean;
}

export const SET_AUTO_ARCHIVE_DATE = "SET_AUTO_ARCHIVE_DATE";

export interface SetAutoArchiveDateAction {
    type: typeof SET_AUTO_ARCHIVE_DATE;
    archiveDate: number | null;
}

export const SET_CONTEXT_ATTACHMENT = "SET_CONTEXT_ATTACHMENT";

export interface SetContextAttachmentAction {
    type: typeof SET_CONTEXT_ATTACHMENT;
    attachment: AttachmentDto | undefined;
}

export const SET_AUTO_ARCHIVE_DATE_ON_EMAIL = "SET_AUTO_ARCHIVE_DATE_ON_EMAIL";

export interface SetAutoArchiveDateOnEmailAction {
    type: typeof SET_AUTO_ARCHIVE_DATE_ON_EMAIL;
    autoArchiveDate: number | null;
    emailIds: number[];
}

export type EmailActionTypes =
    | DraftEmailHeadersLoadedAction
    | SelectEmailHeadersAction | SelectOutgoingEmailsAction | SelectAllEmailHeadersAction
    | GetEmailContentAsyncAction
    | ShowEmailHeadersAction
    | RemoveRemoteOperationsAction
    | DeleteDraftEmailsAction
    | ShowImageAttachmentAction | ShowInlineAttachmentAction | ShowAttachmentContentAction
    | ShowEmailContentAction | ClearItemContentAction | ToggleExpandContentAction
    | ShowEmailCategoryDialogAction
    | SelectCategoryFiltersAction
    | BeginMoveEmailsAction
    | AnalyzeEmailAsyncAction
    | StopSendingEmailsAsyncAction
    | GetEmailSourceAsyncAction
    | RemoveGreylistedEmailsAction
    | ShowArchiveSettingsDialogAction | SetAutoArchiveDateAction | SetAutoArchiveDateOnEmailAction
    | ShowRemindMeLaterSettingsDialogAction | SetRemindMeLaterDateAction | SetRemindMeLaterDateOnEmailAction
    | SetContextAttachmentAction
    ;
