import {FullEmailContent, FullEmailThreadContent} from "../domain/EmailContentDto";
import {getStore_DoNotCall} from "../BootStrap";
import {ensurePgpKeysLoaded, getKeyDescriptions, SecretKey} from "../util/PGPUtil";
import {SystemActions} from "../actions/SystemActions";
import {DecryptedEmail, decryptEncryptedEmail, findEmailDecryptionKeys, verifyEmailSignatures} from "./EncryptedEmailHelper";
import {GET_EMAIL_CONTENT_ASYNC, GetEmailContentAsyncAction} from "../actions/EmailActionTypes";
import {AccountsActions} from "../redux/AccountsSlice";
import {AppDispatch} from "../AppStore";

export function isEncryptedEmailContent(email: FullEmailContent): boolean {
    return email.Content.startsWith("-----BEGIN PGP MESSAGE-----");
}

export function isSignedEmailContent(email: FullEmailContent): boolean {
    return email.Content.includes("-----BEGIN PGP SIGNED MESSAGE-----");
}

export async function handleViewEncryptedEmailContent(emailThread: FullEmailThreadContent, dispatch: AppDispatch) {
    let applicableSecretKeys: SecretKey[] = [];
    const store = getStore_DoNotCall();

    const someEncrypted = emailThread.Emails.some(e => e.IsEncrypted);
    
    const {accountsState} = store.getState();
    try {
        await ensurePgpKeysLoaded(dispatch, accountsState);

        const {secretKeys} = store.getState().accountsState;

        if (secretKeys.length === 0 && someEncrypted) {
            dispatch(SystemActions.showWarning("You don't have any PGP secret keys installed"));
            return;
        }

        for (const email of emailThread.Emails) {
            if (email.IsEncrypted) {
                applicableSecretKeys = await findEmailDecryptionKeys(email.Content, email.ContentType, secretKeys);
                break;// TODO: this assumes all use the same keys!
            }
        }
    } catch (e: any) {
        console.warn("Unable to decrypt/verify email", e);
        dispatch(SystemActions.showFormattedError("Unable to decrypt email: ", e));
        return;
    }

    try {
        const {pgpKeys} = store.getState().accountsState;
        const publicKeys = pgpKeys.map(k => k.key);

        const newThreadContent: FullEmailThreadContent = {
            ...emailThread,
            Emails: [],
        }
        for (const email of emailThread.Emails) {
            let result: DecryptedEmail;
            if (email.IsEncrypted) {
                result = await decryptEncryptedEmail(email.Content, email.ContentType, applicableSecretKeys, publicKeys, accountsState.password);
            } else {
                result = await verifyEmailSignatures(email.Content, publicKeys);
            }

            const newEmailContent: FullEmailContent = {
                ...email,
                DecryptedContent: result,
                Subject: result.subject ?? email.Subject,
                PgpErrors: result.errors,
                DecryptedAttachments: result.attachments,
            };
            newThreadContent.Emails.push(newEmailContent);
        }
        
        const action: GetEmailContentAsyncAction = {type: GET_EMAIL_CONTENT_ASYNC, content: newThreadContent};
        dispatch(action);
    } catch (e: any) {
        if (someEncrypted) {
            console.info("Unable to decrypt email", e);

            const message = accountsState.password.length > 0 ? e.message : "";
            const keyDescription = getKeyDescriptions(applicableSecretKeys);
            dispatch(AccountsActions.itemNeedsPassword({record: emailThread, errorMessage: message, keyName: keyDescription}));
        } else {
            console.info("Unable to verify email", e);
        }

        const action: GetEmailContentAsyncAction = {type: GET_EMAIL_CONTENT_ASYNC, content: emailThread};
        dispatch(action);
    }
}
