import React, {FC, ForwardedRef} from "react";
import {useSelector} from "react-redux";
import {AppState} from "../../AppState";
import {AttachmentContentView} from "./AttachmentContentView";
import {ContentSafetyResult} from "../../util/SafetyTransformer";
import classNames from "classnames";

export interface InlineAttachmentViewerProps {
    safeContent: ContentSafetyResult;
    contentWidth: number | undefined;
    contentRef: ForwardedRef<HTMLDivElement>;
    emailId: number;
}

export const InlineAttachmentViewer: FC<InlineAttachmentViewerProps> = ({safeContent, contentWidth, contentRef, emailId}) => {

    const imageContent = useSelector(({itemState}: AppState) => itemState.imageContent);
    const inlineContent = useSelector(({itemState}: AppState) => itemState.inlineContent);

    if (imageContent && imageContent.emailId === emailId) {
        return <div className="email-image"><img src={imageContent.url} alt="Attachment Content"/></div>;
    }
    if (inlineContent && inlineContent.EmailId === emailId) {
        return <AttachmentContentView content={inlineContent}/>;
    }

    return <div className={classNames("email-content", {"simple-content": !safeContent.hasOneHundredPercent})}
                id={"emailContentDiv" + emailId}
                style={contentWidth ? {} : {opacity: 0}}
                ref={contentRef}
                dangerouslySetInnerHTML={({__html: safeContent.safeContent})}/>;
}
