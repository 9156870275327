import {CssParser} from '../parsers/CssParser';
import {CssRule} from '../parsers/CssRule';
import {CssProperty} from '../parsers/CssProperty';
import _, {clamp} from 'lodash';
import {parseCSSColor, rgbToHsl} from "./CssColourParser";
import {EmailClassType, getEmailClassType, getPixelSize, isContentIdValue, isRedgateSoftwareTracker, isSpacerImage, isTrackerImage, isWhitelistedUrl} from "./TransformUtil";
import {ImageWhitelistDto} from "../redux/PreferenceActions";

const Safety_BlockedImage = "/Static/Images/BlockedImage.png";
const BlankImage = "/Static/Images/blank.gif";

const CssContentSelector = "#emailContentDiv";
const CssClassPrefix = "x-email-";

const ConsiderDarkThreshold = 50;

export interface ContentSafetySettings {
    applySafety: boolean;
    applyJunkSafety: boolean;
    darkTheme: boolean;
    mobile: boolean;
    containerWidth: number;
    whitelistedImages: ImageWhitelistDto;
    contentWidth?: number;
    excludeReplies?: boolean;
}

export interface ContentSafetyResult {
    safeContent: string;
    safetyApplied: boolean;
    trackerDetected: boolean;
    hasOneHundredPercent: boolean;
}

function hex(integer: number) {
    const str = Number(integer).toString(16);
    return str.length === 1 ? "0" + str : str;
}

export function applyEmailContentSafetySettings(emailId: number, content: string, isHtmlContent: boolean, settings: ContentSafetySettings): ContentSafetyResult {

    if (isHtmlContent) {
        const newDoc = document.implementation.createHTMLDocument();

        const htmlElt = newDoc.getElementsByTagName("html")[0];

        const transformedDoc = newDoc.createElement("div");
        transformedDoc.innerHTML = content;
        transformedDoc.className = "email-body";
        if (!settings.contentWidth) {
            transformedDoc.style.width = "1px";
        } /*else if (settings.contentWidth < 150) {
            settings.contentWidth = settings.containerWidth;
        }*/

        htmlElt.append(transformedDoc);

        const transformer = new Transformer(emailId, transformedDoc, settings);

        return transformer.transform();
    } else {
        const noQuoteContent = settings.excludeReplies
            ? content.replace(/(>\s+)?On\s+\d+.*,.*\s+<[^ ]+@[^ ]+>\s+wrote:[\s\S]*/, '')   // Remove replies of the form On ... <someone@example.com> wrote:
            : content;
        const safeContent = `<div class="email-body">${_.escape(noQuoteContent).replace(/\r\n|\n/g, "<br/>")}</div>`;
        return {
            safeContent,
            safetyApplied: false,
            trackerDetected: false,
            hasOneHundredPercent: false,
        };
    }
}

class Transformer {
    readonly result: ContentSafetyResult;
    readonly emailId: number;
    readonly transformedDoc: HTMLElement;
    readonly settings: ContentSafetySettings;
    readonly cssParser: CssParser = new CssParser();
    insideDarkBackgroundStack: boolean[] = [];
    addedToBackgroundStack = false;
    inQuotedSection = false;
    inPotentialQuotedSection: Element | ChildNode | null = null;

    constructor(emailId: number, transformedDoc: HTMLElement, settings: ContentSafetySettings) {
        this.transformedDoc = transformedDoc;
        this.settings = settings;
        this.emailId = emailId;
        this.result = {
            safeContent: "",
            safetyApplied: false,
            trackerDetected: false,
            hasOneHundredPercent: false,
        };
    }

    transform(): ContentSafetyResult {
        this.insideDarkBackgroundStack = [];
        this.inQuotedSection = false;
        this.inPotentialQuotedSection = null;
        this.transformElement(this.transformedDoc, false);

        if (this.settings.applyJunkSafety) {
            this.result.safeContent = (this.transformedDoc.textContent || this.transformedDoc.innerText)
                .replace(/[ \t]*([\r\n])+[ \t]*/g, "<br>")
                .replace(/(<br>)+/g, "<br>");
        } else {
            this.result.safeContent = this.transformedDoc.outerHTML;
        }
        return this.result;
    }

    private transformElement(element: Element, insideAnchorElt: boolean): boolean {
        let needToRemoveBackgroundFromStack = false;
        this.addedToBackgroundStack = false;
        if (this.inQuotedSection) {
            element.remove();
            return true;
        }
        if (element !== this.transformedDoc) {
            switch (element.nodeName) {
                case "LINK":
                case "SCRIPT":
                case "OBJECT":
                case "META":
                case "IFRAME":
                case "EMBED":
                case "AUDIO":
                case "VIDEO":
                case "TRACK":
                case "SOURCE":
                case "TITLE":
                    // this.result.safetyApplied = true;
                    element.remove();
                    return true;
                case "FORM":
                    element.setAttribute("action", "");
                    element.setAttribute("target", "_blank");
                    element.setAttribute("rel", "noopener noreferrer");
                    break;
                case "STYLE":
                    this.transformCss(element as HTMLStyleElement);
                    break;
                case "BLOCKQUOTE":
                    // TODO: improve this logic!
                    if (this.settings.excludeReplies) {
                        element.remove();
                        return true;
                    }
                    break;
            }

            const emailClassType = this.transformAttributes(element, insideAnchorElt);
            // TODO: improve this logic so we don't exclude block quotes in the middle of the email!
            if (emailClassType && this.settings.excludeReplies) {
                element.remove();
                return true;
            }
            if (this.addedToBackgroundStack) {
                needToRemoveBackgroundFromStack = true;
            }
        }

        let inQuotedContent = false;
        for (let i = 0; i < element.childNodes.length; i++) {
            const child = element.childNodes[i];
            if (inQuotedContent) {
                child.remove();
                i--;
            } else if (child.nodeType === Node.ELEMENT_NODE) {
                if (this.transformElement(child as Element, insideAnchorElt || child.nodeName === "A")) {
                    i--;
                }
            } else if (child.nodeType === Node.COMMENT_NODE) {
                child.textContent = "";
            } else if (child.nodeType === Node.TEXT_NODE) {
                if (this.settings.excludeReplies && !this.inQuotedSection) {
                    if (child.nodeValue?.match(/On .*<.*>.*wrote:/) ||
                        child.nodeValue?.includes("--- Original message ---")) {
                        // In a quoted section!
                        this.inQuotedSection = inQuotedContent = true;
                        child.nodeValue = child.nodeValue.replace(/-+-- Original message --+|On .*<.*>.*wrote:/, '');
                    } else if (child.nodeValue === "From:") {
                        if (this.inPotentialQuotedSection instanceof Element) {
                            this.inQuotedSection = inQuotedContent = true;
                            child.nodeValue = "";
                            this.inPotentialQuotedSection.outerHTML = "";
                            this.inPotentialQuotedSection = null;
                            console.debug("Removing quoted section (bordered)");
                        } else {
                            this.inPotentialQuotedSection = child;
                        }
                    } else if (this.inPotentialQuotedSection && child.nodeValue?.match(/[a-zA-Z0-9._+-]+@[a-zA-Z0-9._+-]+/)) {
                        this.inQuotedSection = inQuotedContent = true;
                        child.nodeValue = "";
                        this.inPotentialQuotedSection.nodeValue = "";
                        this.inPotentialQuotedSection = null;
                        console.debug("Removing quoted section (From)");
                    } else if (this.inPotentialQuotedSection && child.nodeValue?.trim()) {  // Only reset the quoted section if text has been detected
                        this.inPotentialQuotedSection = null;
                    }
                }
            } else {
                console.log("Node type", child.nodeType);
            }
        }
        if (needToRemoveBackgroundFromStack) {
            this.insideDarkBackgroundStack.pop();
        }
        return false;
    }

    private transformCss(element: HTMLStyleElement) {
        if (this.settings.applyJunkSafety) {
            element.textContent = "";
            this.result.safetyApplied = true;
            return;
        }
        const cssRules = this.cssParser.ParseCss(element.textContent || "");

        element.textContent = cssRules
            .map(rule => this.transformCssRule(rule, element))
            .join("\r\n");
    }

    private transformCssRule(cssRule: CssRule, containerElement: HTMLElement | undefined): CssRule {
        for (const selector of cssRule.selectors) {
            const items = selector.value.split(' ');

            selector.value = CssContentSelector + this.emailId + " " + items.map(item => this.transformSelectorItem(item)).join(" ");
        }

        this.reformatCssProperties(cssRule.properties, cssRule.selectors.join(","), containerElement);

        return cssRule;
    }

    private reformatCssProperties(properties: CssProperty[], containerName: string, containerElement: Element | undefined) {
        const sortedProperties = [...properties].sort((a, b) => (b.name.includes("background") ? 1 : 0) - (a.name.includes("background") ? 1 : 0));
        for (const property of sortedProperties) {
            const lowerName = property.name.toLowerCase();
            if (lowerName === "position") {
                if (property.value !== "static") {
                    property.value = "static";
                }
            } else if (lowerName === "background" || lowerName.startsWith("border")) {
                property.value = this.transformCssTokenColours(property.value, lowerName === "background");
            } else if (lowerName === "color" || lowerName.endsWith("-color")) {
                const colour = this.transformCssColour(property.value, lowerName.startsWith("background"));
                if (colour) {
                    property.value = colour;
                    continue;
                }
            } else if (lowerName === "max-width" && this.settings.contentWidth) {
                if (containerName.includes("email-layout") || containerName.includes("email-column")) {
                    property.value = containerName + " " + property.value;
                    continue;
                }
            } else if ((lowerName === "width" || lowerName === "max-width" || lowerName === "min-width") && this.settings.contentWidth) {
                if (property.value === "100%") {
                    this.result.hasOneHundredPercent = true;
                }
                if (containerName.includes("email-layout") || containerName.includes("email-column")) {
                    property.value = containerName + " " + property.value;
                } else {
                    let width = getPixelSize(property.value, this.settings.containerWidth);

                    /*if (property.value.startsWith("calc") && this.settings.containerWidth) {
                        property.value = this.settings.containerWidth.toString();//"unset";
                    } else*/
                    if (property.value !== "0px" && !property.value.endsWith("%") && width > 30 && !property.value.endsWith("important")) {
                        const scaledSize = this.getScaledSize(width);
                        if (lowerName === "min-width") {
                            if (property.value === "100%") {
                                property.value = "unset";
                                continue;
                            } else {
                                const widthProperty = properties.find(p => p.name === "width");
                                if (widthProperty) {
                                    const value = getPixelSize(widthProperty.value);
                                    if (value >= width * 0.9) {
                                        property.value = "unset";
                                        continue;
                                    }
                                }
                            }
                        }
                        if (lowerName === "max-width") {
                            property.value = "ignored";
                        } else {
                            property.value = scaledSize + "px";
                            const ratio = scaledSize / width;
                            if (ratio) {    // Maintain aspect ratio
                                const heightProperty = properties.find(p => p.name === "height");
                                if (heightProperty) {
                                    heightProperty.value = "auto";
                                }
                            }
                        }
                    }
                }
                continue;
            } else if (lowerName.startsWith("padding") && this.settings.mobile && this.settings.contentWidth) {
                const tokens = property.value.split(/\s+/);
                let result = "";
                for (const token of tokens) {
                    if (token.endsWith("px")) {
                        result += clamp((parseFloat(token) / 2), -10, 10) + "px";
                    } else {
                        result += "0px";
                    }
                    result += " ";
                }
                property.value = result.trimEnd();
            } else if (lowerName === "mix-blend-mode" && this.settings.darkTheme) {
                property.value = "unset";
            }
            property.value = property.value.replace(/[a-zA-Z0-9]+:\/\/[^;)}'\\"]+/g, match => this.reformatExternalSourceToProxy(match, true));
        }

        // 2nd pass
        for (const property of sortedProperties) {
            const lowerName = property.name.toLowerCase();
            // Ensure that min-width properties can't exceed width
            if (lowerName === "min-width") {
                const minWidth = getPixelSize(property.value);
                let widthProperty = properties.find(p => p.name === "width");
                if (widthProperty) {
                    const width = getPixelSize(widthProperty.value);
                    if (minWidth > width) {
                        property.value = "unset";
                    }
                }
                widthProperty = properties.find(p => p.name === "max-width");
                if (widthProperty) {
                    const width = getPixelSize(widthProperty.value);
                    if (minWidth > width) {
                        property.value = "unset";
                    }
                }
            }
        }
    }

    private transformAttributeColour(value: string, background: boolean): string {
        if (!this.settings.darkTheme) {
            return value;
        }
        const hslColour = this.transformCssColour(value, background);
        if (hslColour === null) {
            return "";
        }
        const colour = parseCSSColor(hslColour);
        if (colour === null) {
            return "";
        }
        return `#${hex(colour[0])}${hex(colour[1])}${hex(colour[2])}`;
    }

    private transformCssColour(value: string, background: boolean): string | null {
        if (!this.settings.darkTheme) {
            return null;
        }
        const colour = parseCSSColor(value);
        if (colour === null) {
            return null;
        }

        const [r, g, b, alpha] = colour;
        if (alpha < 0.5) {
            return value;
        }

        const hsl = rgbToHsl(r, g, b);
        const h = hsl[0];
        const s = hsl[1];
        let l = 100 - hsl[2];
        if (l > 0 && l < 10) {
            l += 5;
        }

        if (background) {
            if (hsl[2] <= ConsiderDarkThreshold) {
                l = hsl[2];
                if (l < 10) l += 5;
                if (!this.addedToBackgroundStack) {
                    this.addedToBackgroundStack = true;
                    this.insideDarkBackgroundStack.push(true);
                }
            } else if (hsl[2] >= ConsiderDarkThreshold) {
                if (!this.addedToBackgroundStack) {
                    this.addedToBackgroundStack = true;
                    this.insideDarkBackgroundStack.push(false);
                }
            }
        } else {
            if (this.insideDarkBackgroundStack[this.insideDarkBackgroundStack.length - 1]) {
                l = hsl[2];
            }
        }

        if (alpha === 1) {
            return `hsl(${h},${s}%,${l}%)`;
        } else {
            return `hsla(${h},${s}%,${l}%,${alpha})`;
        }
    }

    private transformCssTokenColours(value: string, background: boolean) {
        const safeValue = value.replace(/(\([^)]+\))/g, ss => ss.replace(/ /g, ""));
        const tokens = safeValue.split(" ");

        for (let i = 0; i < tokens.length; i++) {
            const newValue = this.transformCssColour(tokens[i], background);
            if (newValue) {
                tokens[i] = newValue;
            }
        }

        return tokens.join(' ');
    }

    private transformSelectorItem(selector: string): string {
        if (selector.toLowerCase() === "body") {
            return ".email-body";
        }
        const classStartIndex = selector.indexOf(".");
        if (classStartIndex === 0) {
            return "." + CssClassPrefix + this.emailId + "." + CssClassPrefix + selector.substring(1);
        } else if (classStartIndex > 0) {
            return selector.substring(0, classStartIndex) + "." + CssClassPrefix + this.emailId + "." + CssClassPrefix + selector.substring(classStartIndex + 1);
        }
        return selector;
    }

    private transformAttributes(element: Element, insideAnchorElt: boolean): EmailClassType {
        let classType: EmailClassType = "";
        const sortedAttribute = [...element.attributes].sort((a, b) => (b.nodeName.toLowerCase().includes("bgcolor") ? 1 : 0) - (a.nodeName.toLowerCase().includes("bgcolor") ? 1 : 0))
        for (const attribute of sortedAttribute) {
            switch (attribute.nodeName.toLowerCase()) {
                case "background":
                    attribute.value = this.reformatExternalSourceToProxy(attribute.value, true);
                    break;
                case "color":
                    attribute.value = this.transformAttributeColour(attribute.value, false);
                    break;
                case "bgcolor":
                    attribute.value = this.transformAttributeColour(attribute.value, true);
                    break;
                case "src":
                    this.handleSourceAttribute(element, attribute, insideAnchorElt);
                    break;
                case "srcset":
                    this.handleSourceSetAttribute(element, attribute, insideAnchorElt);
                    break;
                case "href":
                    this.handleHrefAttribute(element, attribute);
                    break;
                case "class":
                    classType = getEmailClassType(classType, attribute.value);
                    this.handleClassAttribute(attribute);
                    break;
                case "style":
                    this.handleStyleAttribute(attribute, element);
                    break;
                case "width":
                    const ratio = this.handleSizeAttribute(attribute);
                    if (ratio) {    // Maintain aspect ratio
                        const heightAttribute = element.attributes.getNamedItem("height");
                        if (heightAttribute) {
                            element.attributes.removeNamedItem(heightAttribute.name);
                            (element as HTMLDivElement).style.objectFit = "contain";
                        }
                    }
                    break;
                case "padding":
                    if (this.settings.mobile) {
                        element.removeAttribute(attribute.nodeName);
                    }
                    break;
            }
        }
        return classType;
    }

    private handleSizeAttribute(attribute: Attr, ratio?: number) {
        if (attribute.value === "100%") {
            this.result.hasOneHundredPercent = true;
        } else if (!attribute.value.endsWith("%") && !attribute.value.endsWith("important") && this.settings.contentWidth) {
            const size = parseFloat(attribute.value);
            if (size > 30) {
                // Attempt to resize to fill the width of the container when encountering a large element - prevents column-like emails
                const newSize = ratio ? ratio * size : this.getScaledSize(size);
                attribute.value = newSize.toString();
                return newSize / size;
            }
        }
    }

    private handleStyleAttribute(attribute: Attr, containerElement: Element | undefined) {
        if (attribute.name === "style" && attribute.value.startsWith("border:none;border-top:solid #E1E1E1 1.0pt;")) {
            this.inPotentialQuotedSection = containerElement!;
            console.debug("Detected potential quoted section");
        }

        const properties = this.cssParser.ParseCssProperties(attribute.value);

        this.reformatCssProperties(properties, attribute.name, containerElement);

        attribute.value = properties.join("");
    }

    private handleClassAttribute(attribute: Attr) {
        const classes = attribute.value;
        if (!classes) {
            attribute.value = "";
        }
        attribute.value = classes
            .split(' ')
            .map(c => c.trim())
            .filter(c => c.length > 0)
            .map(c => CssClassPrefix + this.emailId + " " + CssClassPrefix + c)
            .join(" ");
    }

    private handleHrefAttribute(element: Element, attribute: Attr) {
        const link = attribute.value || "";

        if (link.startsWith("mailto:")) {
            element.setAttribute("href", `#app=email&mailto=` + encodeURIComponent(link));
            element.setAttribute("title", link);
        } else {
            element.setAttribute("href", `/Mail/LinkSafety.aspx?e=1&url=${encodeURIComponent(encodeURIComponent(link))}`);
            element.setAttribute("title", link);
            element.setAttribute("target", "_blank");
            element.setAttribute("rel", "noopener noreferrer");
        }
    }

    private handleSourceAttribute(element: Element, attribute: Attr, insideAnchorElt: boolean) {
        if (isContentIdValue(attribute.value)) {
            element.setAttribute("src", `/Mail/DownloadAttachment.aspx?emailId=${this.emailId}&cid=${encodeURIComponent(attribute.value.substring(4))}`);
        } else if (element.nodeName === "IMG") {
            const imgElt = element as HTMLImageElement;
            const source = this.reformatExternalSourceToProxy(imgElt.src, false);

            this.transformImage(source, element as HTMLImageElement, insideAnchorElt);
        }
        this.applyChecksOnImageElement(element);
    }

    private handleSourceSetAttribute(element: Element, attribute: Attr, insideAnchorElt: boolean) {
        const value = attribute.value.split(',')[0];
        if (isContentIdValue(value)) {
            element.setAttribute("srcset", `/Mail/DownloadAttachment.aspx?emailId=${this.emailId}&cid=${encodeURIComponent(value.substring(4))}`);
        } else if (element.nodeName === "IMG") {
            const imgElt = element as HTMLImageElement;

            this.result.safetyApplied = true;
            element.removeAttribute(attribute.name);

            const source = this.reformatExternalSourceToProxy(value, false);
            this.transformImage(source, imgElt, insideAnchorElt);
        }
        this.applyChecksOnImageElement(element);
    }

    private applyChecksOnImageElement(element: Element) {
        const imgElt = element as HTMLImageElement;
        if (imgElt) {
            // if (!imgElt.style.maxWidth) {
            //     imgElt.style.maxWidth = "100%";
            // }
            // if(imgElt.alt.includes("
        }
    }

    private transformImage(source: string, imgElt: HTMLImageElement, insideAnchorElt: boolean): void {
        imgElt.src = source;
        if (!source.startsWith("/Mail3/Proxy.aspx")) {
            if (insideAnchorElt) {
                imgElt.style.height = "initial";
                imgElt.style.maxHeight = "32px";
            } else {
                imgElt.style.width = "0px";
                imgElt.style.height = "0px";
            }
        }
    }

    private reformatExternalSourceToProxy(externalUrl: string, background: boolean): string {
        const applySafety = this.settings.applySafety;

        if (externalUrl.startsWith("#")) {
            return this.transformCssColour(externalUrl, false) ?? externalUrl;
        }
        if (isRedgateSoftwareTracker(externalUrl)) {
            return BlankImage;

        } else if (isSpacerImage(externalUrl) && applySafety) {
            return BlankImage;

        } else if (isTrackerImage(externalUrl)) {
            this.result.trackerDetected = true;
            this.result.safetyApplied = this.result.safetyApplied || applySafety;
            return BlankImage;

        } else if (!applySafety || isWhitelistedUrl(externalUrl, this.settings.whitelistedImages)) {
            return "/Mail3/Proxy.aspx?i=1&l=" + encodeURIComponent(encodeURIComponent(externalUrl));

        } else if (background && this.settings.applySafety) {
            this.result.safetyApplied = true;
            return "";
        } else {
            this.result.safetyApplied = true;
            if (this.settings.contentWidth) console.log("Original URL:", externalUrl);
            return Safety_BlockedImage;
        }
    }

    private getScaledSize(numberValue: number) {
        const maxWidth = (this.settings.contentWidth ?? 600);
        const newSize = (numberValue / maxWidth) * this.settings.containerWidth;
        if (newSize > numberValue) {
            if (newSize > maxWidth) {
                return maxWidth;
            }
            return numberValue;
        }
        return Math.round(clamp(newSize, -maxWidth, maxWidth));
    }
}
